import React from "react";
import VideoSection from "containers/VideoSection";
import VideoInfo from "containers/VideoInfo";
import BrowsingMenu from "containers/BrowsingMenu";
import BrowsingSection from "containers/BrowsingSection";
import Sidebar from "containers/Sidebar";

const WITH_VIDEO = 1 << 0;
const WITH_SIDEBAR = 1 << 1;
const WITH_INFO = 1 << 2;
const WITH_MENU = 1 << 3;
const WITH_BROWSING = 1 << 4;
const SHOW_ALL = 31;

/**
* Widget layout with the video player on the top.
* @prop {number} view Bit hash that determines what components to show. Components match up to bits as follows:
*   1: VideoSection
*   2: Sidebar
*   4: VideoInfo
*   8: BrowsingMenu
*  16: BrowsingSection
* @prop *OuterWindowProps All props from OuterWindow are passed through this component to the appropriate children.
*/
export default class OuterWindowVideoBottom extends React.Component {
  /**
  * Renders a container for the widget which contains the sections of the widget in a videoBottom layout.
  * Also hides or shows sections based on view tag in the url.
  */
  render () {
    let view;
    let cls = "";
    if (!this.props.view && this.props.view !== 0) {
      view = SHOW_ALL;
    } else {
      view = this.props.view;
    }
    if (!this.props.hidden && (view & WITH_BROWSING)) {
      cls = "hidden";
    }
    return (
      <div id='wrapper'>
        {(view & WITH_BROWSING)
          ? <BrowsingSection layout={"videoBottom"} />
          : ""
        }
        {(view & (WITH_INFO | WITH_MENU))
          ? <div id='info-bar'>
            {(view & WITH_INFO)
              ? <VideoInfo/>
              : ""
            }
            {(view & WITH_MENU)
              ? <BrowsingMenu layout={"videoBottom"} />
              : ""
            }
          </div>
          : ""
        }
        {(view & (WITH_VIDEO | WITH_SIDEBAR))
          ? <div id='video-area' className={cls}>
            {(view & WITH_VIDEO)
              ? <VideoSection/>
              : ""
            }
            {(view & WITH_SIDEBAR)
              ? <Sidebar/>
              : ""
            }
          </div>
          : ""
        }
      </div>
    );
  }
}
