import React from "react";
import ScheduleItem from "./ScheduleItem";

import "./Schedule.css";

export default class Schedule extends React.Component {
  render () {
    let items = [];
    this.props.displaySchedule.forEach((element) => {
      items.push(<ScheduleItem item={element}
        key={element.get("localid")}
        layout={this.props.layout}
        embedVideoPlay={this.props.embedVideoPlay}
        playVideoAsync={this.props.playVideoAsync}
        showSeconds={this.props.showSeconds}
      />);
    });
    return (
      <div id='schedule'>
        {items}
      </div>
    );
  }
}
