import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import "./LoopToggle.css";

/**
 * Component that allows looping of playlist/program queue to be toggled
 */
export default class LoopToggle extends PureComponent {
  render () {
    let {loop, setLoop} = this.props;

    let cls = `toggleButton ${loop ? "on" : ""}`;

    let loopIcon = "\u21BA";

    return (
      <button className={cls}
        onClick={() => { setLoop(!loop); }}
        title={"Toggle Looping"}>
        <span className='toggleButtonIndicator'>{loopIcon}</span>
        <span className='toggleButtonLabel'>Loop</span>
      </button>
    );
  }
}

LoopToggle.propTypes = {
  loop: PropTypes.bool.isRequired,
  setLoop: PropTypes.func.isRequired
};
