import React from "react";
import AgendaLink from "./AgendaLink.js";

import "./AgendaItem.css"

/**
* A single item on the Agenda. Can contain children and links.
* @prop {object} item Information about this specific agenda item.
* @prop {string} item.title Title of this agenda item.
* @prop {string} item.time Time of this agenda item in hh:mm:ss format.
* @prop {string} item.description Description of this agenda item.
* @prop {string} item.key The key of the agenda item.
* @prop {array} item.items List of child items of this agenda item.
* @prop {array} item.links List of links of this agenda item.
* @prop {string} endTime Optional prop. Time of the next chronologically occuring item, in hh:mm:ss format.
* @prop {string} nextTime Optional prop. Time of the next item on the same hierarchical level as this item.
* @prop {array} highlight The address of the agenda item that should be highlighted, relative to this item's level.
* @prop {number} time The current time of the video. Used to help determine if the agenda should be updated.
* @prop {function} gotoTime Used to jump the time of the video when this item is clicked on.
*/
export default class AgendaItem extends React.Component {
  shouldComponentUpdate (nextProps) {
    return (this.props.item !== nextProps.item ||
            this.props.time !== nextProps.time);
  }

  /**
  * Causes the current video to jump to the time of the AgendaItem.
  */
  timeJump = () => {
    let time = 0;
    let timeString = this.props.item.get("time").split(":");
    for (let i = 0; i < timeString.length; i++) {
      let stringIndex = timeString.length - 1 - i;
      time += (parseInt(timeString[stringIndex]) * Math.pow(60, i));
    }
    this.props.gotoTime(time);
  };

  /**
  * Renders an AgendaItem that displays the name, description, time, and links of an item in the video's agenda,
  * as well as any child items. AgendaItems may also be highlighted.
  */
  render () {
    let items = [];
    let links = [];
    let cls = "video-agenda-item-contents";
    let highlight = null;
    if (this.props.item.get("items")) {
      items = this.props.item.get("items").map((item, index) => {
        if (this.props.highlight && this.props.highlight.size > 0 && this.props.highlight.get(0) === index) {
          highlight = this.props.highlight.slice(1);
        }
        if (item.get("items") && item.getIn(["items", 0, "time"])) {
          if (this.props.item.getIn(["items", index + 1])) {
            return <AgendaItem item={item}
              key={item.get("key")}
              gotoTime={this.props.gotoTime}
              time={this.props.time}
              endTime={item.getIn(["items", 0, "time"])}
              highlight={highlight || null}
              nextTime={this.props.item.getIn(["items", index + 1, "time"])} />;
          } else if (this.props.nextTime) {
            return <AgendaItem item={item}
              key={item.get("key")}
              gotoTime={this.props.gotoTime}
              time={this.props.time}
              endTime={item.getIn(["items", 0, "time"])}
              highlight={highlight || null}
              nextTime={this.props.nextTime} />;
          } else {
            return <AgendaItem item={item}
              key={item.get("key")}
              gotoTime={this.props.gotoTime}
              time={this.props.time}
              highlight={highlight || null}
              endTime={item.getIn(["items", 0, "time"])} />;
          }
        } else if (this.props.item.getIn(["items", index + 1])) {
          return <AgendaItem item={item}
            key={item.get("key")}
            gotoTime={this.props.gotoTime}
            time={this.props.time}
            highlight={highlight || null}
            endTime={this.props.item.getIn(["items", index + 1, "time"])} />;
        } else if (this.props.nextTime) {
          return <AgendaItem item={item}
            key={item.get("key")}
            gotoTime={this.props.gotoTime}
            time={this.props.time}
            highlight={highlight || null}
            endTime={this.props.nextTime} />;
        } else {
          return <AgendaItem item={item}
            key={item.get("key")}
            gotoTime={this.props.gotoTime}
            highlight={highlight || null}
            time={this.props.time} />;
        }
      });
    }
    if (this.props.item.get("links")) {
      links = this.props.item.get("links").map((link) => {
        return <AgendaLink link={link} key={link.get("key")} />;
      });
    }
    if (this.props.highlight && this.props.highlight.size === 0) {
      cls = cls + " current";
    }
    return (
      <div className='video-agenda-item'>
        <div className={cls} onClick={this.timeJump}>
          <div className='video-agenda-item-title'>{this.props.item.get("title")}</div>
          <div className='video-agenda-item-time'>{this.props.item.get("time")}</div>
          <div className='video-agenda-item-description'>{this.props.item.get("description")}</div>
        </div>
        <div className='video-agenda-item-links'>
          {links}
        </div>
        <div className='video-agenda-item-children'>
          {items}
        </div>
      </div>
    );
  }
}
