// vim: et sw=2 ts=2 sts=2
import Immutable from "immutable";

// Action Creators
export const actions = {

  /**
  * Reports that an asynchronous call is going out, and increments asyncCallsOut.
  *   Call before ajax calls, unless you do not want spinner.
  */
  reportOut: () => {
    return {
      type: "REPORT-OUT"
    };
  },

  /**
  * Reports that an asynchronous call has finished (not necessarily successfully).
  *   ALWAYS call on ajax call conclusion if you called it before ajax.
  *   Infinite loading spinner indicates that for some reason reportOut was called,
  *   but reportIn was not (usually JS error).
  */
  reportIn: () => {
    return {
      type: "REPORT-IN"
    };
  }
};

// Reducers
export const initialState = Immutable.Map({
  asyncCallsOut: 0 // Number of asynchronous ajax calls waiting for a response
});
export default (state = initialState, action) => {
  switch (action.type) {
    case "REPORT-OUT":
      return state.set("asyncCallsOut", state.get("asyncCallsOut") + 1);
    case "REPORT-IN":
      return state.set("asyncCallsOut", state.get("asyncCallsOut") - 1);
    default:
      return state;
  }
};
