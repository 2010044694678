import React from "react";

import "./ScheduleItem.css";

export default class ScheduleItem extends React.Component {
  handleClick (guid) {
    let splitGuid = guid.split("}");
    let formatGuid = splitGuid[0].slice(1);
    this.props.playVideoAsync({guid: formatGuid});
  }

  render () {
    let startTime = "";
    let cls = "scheduleItem";
    let title = "";
    let clickAction = null;
    let channelDisplay = "";
    if (this.props.layout === "schedule" && !this.props.embedVideoPlay) {
      cls += " playNowOff";
      clickAction = () => {};
      title = this.props.item.get("display");
    } else if (!this.props.item.get("meta") || !this.props.item.getIn(["meta", "_rpath"])) {
      cls += " unavailable";
      clickAction = () => {};
      title = "Unavailable for play now";
    } else {
      title = "Click to play now";
      clickAction = () => { this.handleClick(this.props.item.getIn(["meta", "guid"])); };
    }
    if (this.props.item.get("channel")) {
      startTime = new Date(this.props.item.get("start unix") * 1000).toLocaleString();
    } else {
      startTime = new Date(this.props.item.get("start unix") * 1000).toLocaleTimeString();
    }
    if (!this.props.showSeconds) {
      startTime = startTime.replace(/:\d\d /, " ");
    }
    if (this.props.item.get("channel")) {
      channelDisplay = [
        <div className='scheduleItemName' key={"name"}>{this.props.item.get("display")}</div>,
        <div className='scheduleItemStart' key={"start"}>{startTime}</div>,
        <div className='scheduleItemClass' key={"channel"}>{this.props.item.get("channel")}</div>
      ];
    } else {
      channelDisplay = [
        <div className='scheduleItemStart' key={"start"}>{startTime}</div>,
        <div className='scheduleItemName' key={"name"}>{this.props.item.get("display")}</div>
      ];
    }
    return (
      <div className={cls} onClick={clickAction} title={title}>
        {channelDisplay}
      </div>
    );
  }
}
