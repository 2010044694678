import React from "react";
import MenuButton from "./MenuButton.js";
import SearchBar from "./SearchBar.js";
import HideButton from "./HideButton.js";

import "./BrowsingMenu.css";

/**
* Menu component for controlling what's displayed in the BrowsingSection.
* @prop {boolean} browsingSection Whether the BrowsingSection is currently hidden or not.
* @prop {string} navPath The current navpath of the BrowsingSection.
* @prop {function} navigate Used to navigate the BrowsingSection to a given path.
* @prop {function} toggleBrowsingSection Used to show or hide the BrowsingSection.\
*/
export default class BrowsingMenu extends React.Component {
  /**
  * Renders a menu bar that contains a search bar and buttons for navigating the BrowsingSection.
  */
  render () {
    return (
      <div id='browsing-menu'>
        <SearchBar navigate={this.props.navigate} getTotalItems={this.props.getTotalItems} />
        <div id='browsing-menu-button-section'>
          <MenuButton navigate={this.props.navigate}
            navPath={this.props.navPath}
            browsingSection={this.props.browsingSection}
            toggleBrowsingSection={this.props.toggleBrowsingSection}
            destination={"recent"} />
          <MenuButton navigate={this.props.navigate}
            navPath={this.props.navPath}
            browsingSection={this.props.browsingSection}
            toggleBrowsingSection={this.props.toggleBrowsingSection}
            destination={"programs"} />
          <MenuButton navigate={this.props.navigate}
            navPath={this.props.navPath}
            browsingSection={this.props.browsingSection}
            toggleBrowsingSection={this.props.toggleBrowsingSection}
            destination={"playlists"} />
          <MenuButton navigate={this.props.navigate}
            navPath={this.props.navPath}
            browsingSection={this.props.browsingSection}
            toggleBrowsingSection={this.props.toggleBrowsingSection}
            destination={"live"} />
          <HideButton layout={this.props.layout}
            browsingSection={this.props.browsingSection}
            toggleBrowsingSection={this.props.toggleBrowsingSection} />
        </div>
      </div>
    );
  }
}
