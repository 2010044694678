var exports = module.exports = {};

/**
* Converts time from milliseconds to seconds and then rounds down to the closest interval of minutes amount of minutes.
* @param {number} time Time to be converted in milliseconds.
* @param {number} minutes Interval in minutes to floor time to.
* @returns The converted and floored time in seconds.
*/
exports.convertTimeAndFloor = (time, minutes) => {
  let calcTime = new Date(time);
  let toReturn = Math.floor(time / 1000);
  let sub = Math.floor(calcTime.getMinutes() % minutes);
  return (toReturn - (sub * 60));
};

/**
* Returns what percent of time segment a overlaps with time segment b.
*   As long as units are consistent across all params, the units are irrelevent.
* @param {number} startA The start of time segment a.
* @param {number} endA The end of time segment a.
* @param {number} startB The start of time segment b.
* @param {number} endB The end of time segment b.
* @returns The percentage of overlap between time segment a and time segment b.
*   (Example: 84% overlap would return as 84).
*/
exports.calculateOverlapPercentage = (startA, endA, startB, endB) => {
  let lengthA = endA - startA;
  let [realStart, realEnd] = [
    startB < startA ? startA : startB,
    endA < endB ? endA : endB
  ];
  if (realEnd <= realStart) {
    return 0;
  } else {
    let lengthB = realEnd - realStart;
    return (100 * (lengthB / lengthA));
  }
};

/**
* Takes unix time in seconds and returns timestring hh:mm:ss in 12-hour time.
* @param {number} time Time in unix time.
* @returns A string in the format hh:mm:ss[AM/PM].
*/
exports.convertToHourString = (time) => {
  let date = new Date(time * 1000);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  let period = hours < 12 ? "AM" : "PM";
  hours = hours % 12;
  if (hours === 0) { hours = 12; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return "" + hours + ":" + minutes + ":" + seconds + period;
};

/**
* Takes a duration in seconds and returns a duration string (hh:)mm:ss.
* @param {number} time Duration in seconds.
* @returns A string in the format (hh:)mm:ss.
*/
exports.convertToDurationString = (time) => {
  let hours = Math.floor(time / 3600);
  let minutes = Math.floor((time % 3600) / 60);
  let seconds = Math.floor(time % 60);
  let hoursString = hours ? `${hours}:` : "";
  let minutesString = hours ? `${minutes}:`.padStart(3, "0") : `${minutes}:`;
  let secondsString = `${seconds}`.padStart(2, "0");
  return hoursString + minutesString + secondsString;
};
