import React from "react";

import "./SearchBar.css";

/**
* A search bar for searching the widget's videos.
* @prop {function} navigate Used to navigate the BrowsingSection to a given path.
*/
export default class SearchBar extends React.Component {
  /**
  * Navigates the BrowsingSection to a search with the search value inputted into this SearchBar.
  */
  search = () => {
    this.props.navigate(["search", this.refs.search.value], true)
      .then(this.props.getTotalItems(this.refs.search.value));
  };

  /**
  * Renders a search bar that can be used to search the videos in the widget.
  */
  render () {
    return (
      <div id='browsing-menu-search'>
        <input onKeyPress={(e) => { if (e.charCode === 13) { this.search(); } }}
          placeholder='Search'
          ref='search'
          aria-label='Search'
          aria-required={true}/>
        <button title='Search' onClick={this.search}><img id='search-button-icon' src='SearchIcon.svg' alt=""/></button>
      </div>
    );
  }
}
