import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
import helpers from "helpers/DuckHelpers.js";
import {actions} from "../redux/modules/CommonActions.js";
import {actions as VideoPlayerActions} from "../redux/modules/VideoPlayerDuck.js";
import {actions as BrowsingSectionActions} from "../redux/modules/BrowsingSectionDuck.js";
import {actions as URLActions} from "../redux/modules/URLDuck.js";
import {actions as ScheduleActions} from "../redux/modules/ScheduleDuck.js";
import OuterWindow from "components/OuterWindows/OuterWindow.js";

let mapDispatchToProps = {
  initializeConfig: actions.initializeConfigAsync,
  playVideoAsync: actions.playVideoAsync,
  playLive: actions.playLiveStream,
  navigate: actions.navigateAsync,
  navigateSync: actions.navigate,
  loadVideoData: actions.loadVideoDataAsync,

  videoShouldAutoplay: VideoPlayerActions.videoShouldAutoplay,
  setQueueLoop: VideoPlayerActions.setQueueLoop,

  hideBackButton: BrowsingSectionActions.hideBackButton,
  getTotalItems: BrowsingSectionActions.getTotalItemsAsync,
  retrievePlaycounts: BrowsingSectionActions.retrievePlaycountsAsync,

  updateURLParams: URLActions.updateURLParams,
  updateURL: URLActions.updateURL,

  updateViewers: ScheduleActions.updateViewers,
  retrieveItems: ScheduleActions.retrieveItemsAsync,
  changeView: ScheduleActions.changeView,
};

let mapStateToProps = state => state;

let mergeProps = (stateProps, dispatchProps, ownProps) => {
  let searchProps = {};
  if (ownProps.location && ownProps.location.search) {
    searchProps = queryString.parse(ownProps.location.search);
    searchProps = helpers.parseHash(searchProps);
  }
  return Object.assign({}, stateProps, dispatchProps, ownProps, {urlParams: searchProps});
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(OuterWindow));
