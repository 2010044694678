var React = require("react");

export default class PaginatorButton extends React.Component {
  handlePageChange = () => {
    let toPage = window.prompt("Which page do you want to jump to?");
    toPage = toPage.trim();
    if (toPage.match(/\D/) || toPage.match(/^0+$/)) {
      window.alert("Invalid page: " + toPage + "\nMake sure you only enter a number above 0.");
    } else {
      while (toPage.startsWith("0")) {
        toPage = toPage.slice(1);
      }
      this.props.setCategory("pageNumber", parseInt(toPage));
    }
  };

  render () {
    return (
      <button onClick={this.handlePageChange}>&#8230;</button>
    );
  }
}
