import React from "react";

import "./AgendaLink.css";

/**
* A supplementary link that can be added to an agenda item.
* @prop {object} link Information about the link.
* @prop {string} link.text The text to display the link as.
* @prop {string} link.link The url to link to.
* @prop {string} link.key The key of the link.
*/
export default class AgendaLink extends React.Component {
  shouldComponentUpdate (nextProps) {
    return this.props.link !== nextProps.link;
  }

  /**
  * Renders a link to a given url displayed with given text.
  */
  render () {
    return (
      <a className='video-agenda-link' target='_blank' href={this.props.link.get("link")} rel="noopener noreferrer">
        {this.props.link.get("text")}
      </a>
    );
  }
}
