import React from "react";
import ChannelTab from "./ChannelTab.js";

import "./ChannelTabs.css";

export default class ChannelTabs extends React.Component {
  render () {
    let tabs = [];
    this.props.config.forEach((element) => {
      tabs.push(<ChannelTab display={element.get("filename")}
        thumb={element.get("thumb")}
        number={/:ch([0-9]+)$/.exec(element.get("rpath"))[1]}
        previousDate={this.props.previousDate}
        currentView={this.props.currentView}
        changeView={this.props.changeView}
        key={parseInt(/:ch([0-9]+)$/.exec(element.get("rpath"))[1])}
      />)
    });
    return (
      <div id='channelTabs'>
        {tabs}
      </div>
    );
  }
}
