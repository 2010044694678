import {connect} from "react-redux";
import {actions} from "redux/modules/CommonActions";
import {actions as BrowsingSectionActions} from "../redux/modules/BrowsingSectionDuck.js";

import BrowsingMenu from "components/BrowsingMenu/BrowsingMenu"

const mapDispatchToProps = {
  navigate: actions.navigateAsync,
  toggleBrowsingSection: BrowsingSectionActions.toggleBrowsingSection,
  getTotalItems: BrowsingSectionActions.getTotalItemsAsync,
}

const mapStateToProps = (state) => ({
  browsingSection: state.BrowsingSectionDuck.get("hidden"),
  navPath: state.BrowsingSectionDuck.get("navPath"),
})

export default connect(mapStateToProps, mapDispatchToProps)(BrowsingMenu)
