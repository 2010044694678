// vim: et sw=2 ts=2 sts=2
import Immutable from "immutable";

// Action Creators
export const actions = {

  /**
  * Highlights the agenda item that corresponds to a given time.
  *   Nothing happens if that agenda item is already highlighted (early shortcircuit).
  * @param {number} time The agenda highlighted corresponds to this time, in seconds.
  *   This should be the current time of the video.
  */
  highlightAgenda: function (time) {
    return {
      type: "HIGHLIGHT-AGENDA",
      time
    };
  },

  /**
  * Helper function. Converts a time from a string to seconds as an int.
  * @param {string} timeString A time string in the form "hh:mm:ss". Anything bigger than seconds is optional.
  * @returns Time in seconds as an integer.
  */
  convertTimeToSeconds: function (timeString) {
    let time = 0;
    timeString = timeString.split(":");
    for (let i = 0; i < timeString.length; i++) {
      let stringIndex = timeString.length - 1 - i;
      time += (parseInt(timeString[stringIndex]) * Math.pow(60, i));
    }
    return time;
  },

  /**
  * Helper function. Gets the item in the agenda that occurs as close
  *   to a given time as possible without occuring after that time. Works recursivly.
  * @param {array} items Array containing the items to check.
  *   Either it's the agenda as a whole, or in recursion, the children of an item.
  * @param {number} time The time to check with, in seconds.
  * @param {number} endPeriod The end of the period of time to check. Used for recursivly checking children of an item.
  * @param {array} itemIndex The index(address) of the item that is the parent to items. Used for recursion.
  * @returns An object containing the time of the agenda item found (startTime),
  *   the time of the next agenda item if there is one or the end of the video
  *   if there isn't (endTime), and the address of the item found (index).
  */
  checkAgenda: function (items, time, endPeriod = null, itemIndex = []) {
    let returnItem = Immutable.Map({startTime: 0, endTime: -1, index: []});
    if (items) {
      if (items.get(0)) {
        returnItem = returnItem.set("endTime", actions.convertTimeToSeconds(items.getIn([0, "time"])));
      }
      items.forEach((item, index) => {
        let itemTime = actions.convertTimeToSeconds(item.get("time"));
        if (time >= itemTime) {
          // If next item exists and then if the current time is less than next item's time
          if (items.get(index + 1)) {
            let nextItemTime = actions.convertTimeToSeconds(items.getIn([index + 1, "time"]));
            if (time < nextItemTime) {
              itemIndex.push(index);
              if (item.get("items") && item.get("items").size > 0) {
                returnItem = actions.checkAgenda(item.get("items"), time, nextItemTime, itemIndex);
                if (returnItem.get("index").length === 0) {
                  returnItem = returnItem.set("startTime", itemTime)
                    .set("endTime", actions.convertTimeToSeconds(item.getIn(["items", 0, "time"])))
                    .set("index", itemIndex);
                }
              } else {
                returnItem = returnItem.set("startTime", itemTime)
                  .set("endTime", nextItemTime)
                  .set("index", itemIndex);
              }
            }
          // If there is an end time set for the period and then if the current time is less than that end time
          } else if (endPeriod) {
            if (time < endPeriod) {
              itemIndex.push(index);
              if (item.get("items") && item.get("items").size > 0) {
                returnItem = actions.checkAgenda(item.get("items"), time, itemIndex);
                if (returnItem.get("index").length === 0) {
                  returnItem = returnItem.set("startTime", itemTime)
                    .set("endTime", endPeriod)
                    .set("index", itemIndex);
                }
              } else {
                returnItem = returnItem.set("startTime", itemTime)
                  .set("endTime", endPeriod)
                  .set("index", itemIndex);
              }
            }
          }
        }
      });
    }
    return returnItem;
  }
};

// Reducers
export const initialState = Immutable.Map({
  visible: false, // Whether to show the sidebar or not
  time: 0, // Current time of the video
  timePeriod: Immutable.Map({start: 0, end: -1}), // Current time period of agenda to determine whether or not to update
  videoEnd: -1, // End time of currently playing video
  agenda: Immutable.Map({}), // Agenda to display
  highlight: Immutable.List([]) // List containing address of agenda item to highlight
});
export default (state = initialState, action) => {
  switch (action.type) {
    case "PLAY-VIDEO":
      if (action.data.agenda) {
        return state.set("visible", true)
          .set("agenda", Immutable.fromJS(action.data.agenda))
          .set("videoEnd", action.data.meta.duration)
          .setIn(["timePeriod", "start"], 0)
          .setIn(["timePeriod", "end"], -1);
      } else {
        return state.set("visible", false)
          .set("agenda", Immutable.Map({}))
          .set("videoEnd", -1)
          .setIn(["timePeriod", "start"], 0)
          .setIn(["timePeriod", "end"], action.data.meta.duration + 1);
      }
    case "PLAY-LIVE":
      return state.set("visible", false)
        .set("agenda", Immutable.Map({}))
        .set("videoEnd", -1)
        .setIn(["timePeriod", "start"], 0)
        .setIn(["timePeriod", "end"], -1);
    case "HIGHLIGHT-AGENDA":
      if (action.time < state.getIn(["timePeriod", "start"]) || action.time >= state.getIn(["timePeriod", "end"])) {
        let temp = actions.checkAgenda(state.getIn(["agenda", "items"]), action.time, state.get("videoEnd"));
        return state.set("time", action.time)
          .setIn(["timePeriod", "start"], temp.get("startTime"))
          .setIn(["timePeriod", "end"], temp.get("endTime"))
          .set("highlight", Immutable.List(temp.get("index")));
      }
      return state;
    default:
      return state;
  }
};
