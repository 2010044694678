import Immutable from "immutable";

const DEFAULT_LAYOUT = "videoTop"

// Action Creators
export const actions = {

  /**
  * Sets the layout of the whole widget.
  * @param {string} layout The layout to set to. Valid values are "videoTop" and "videoBottom".
  *   Invalid values cause widget to default to videoTop.
  */
  setLayout: (layout) => {
    return {
      type: "SET-LAYOUT",
      layout
    };
  }
};

// Reducers
export const initialState = Immutable.Map({
  layout: ""
});
export default (state = initialState, action) => {
  switch (action.type) {
    case "SET-LAYOUT":
      return state.set("layout", action.layout);
    case "INITIALIZE-CONFIG":
      let layout = DEFAULT_LAYOUT;
      if (action.config.layout) {
        layout = action.config.layout;
      }
      if(action.config.style) {
        let style = Immutable.fromJS(action.config.style);
        if(style.getIn(['global', 'color', 'hue']) ||
          style.getIn(['global', 'color', 'hue']) === 0) {
          document.body.style.setProperty("--global-color-hue", style.getIn(['global', 'color', 'hue']));
        }
        if(style.getIn(['global', 'color', 'value'])) {
          document.body.style.setProperty("--global-color-value", style.getIn(['global', 'color', 'value']));
        }
        if(style.getIn(['global', 'color', 'saturation'])) {
          document.body.style.setProperty("--global-color-saturation", style.getIn(['global', 'color', 'saturation']));
        }
      }
      return state.set("layout", layout);
    default:
      return state;
  }
};
