import {connect} from "react-redux";
import {actions as VideoInfoActions} from "../redux/modules/VideoInfoDuck.js";

import VideoInfo from 'components/VideoInfo/VideoInfo'

let mapDispatchToProps = {
  toggleWidgetInfoModal: VideoInfoActions.toggleWidgetInfoModal,
  toggleShareModal: VideoInfoActions.toggleShareModal
}

let mapStateToProps = (state) => ({
  data: state.VideoInfoDuck,
  liveViews: state.ScheduleDuck.get("liveViewers"),
  playcounts: state.BrowsingSectionDuck.get("playcounts")
})

export default connect(mapStateToProps, mapDispatchToProps)(VideoInfo);
