import React from "react";

import "./MenuButton.css";

/**
* @prop {boolean} browsingSection Whether the BrowsingSection is currently hidden or not.
* @prop {string} navPath The current navpath of the BrowsingSection.
* @prop {string} destination The destination that this button navigates to.
* @prop {function} navigate Used to navigate the BrowsingSection to a given path.
* @prop {function} toggleBrowsingSection Used to show or hide the BrowsingSection.
*/
export default class MenuButton extends React.Component {
  /**
  * Handles button click. If the BrowsingSection is hidden, it unhides it. Otherwise it navigates to the page
  * associated with this button.
  */
  click = () => {
    if (this.props.browsingSection) {
      this.props.toggleBrowsingSection();
    }
    this.props.navigate([this.props.destination], true);
  };

  /**
  * Renders a button for navigating the BrowsingSection to a specific page.
  */
  render () {
    let cls = "browsing-menu-button";
    let contents = this.props.destination;
    if (this.props.navPath && this.props.navPath.get(0) === this.props.destination) {
      cls = cls + " selected";
    }
    contents = contents.charAt(0).toUpperCase() + contents.slice(1);
    return (
      <button className={cls}
        onClick={this.click}>
        <span>{contents}</span>
      </button>
    );
  }
}
