import React from "react";

import "./HideButton.css";

/**
* Button for hiding the BrowsingSection
* @prop {function} navigate Used to hide the BrowsingSection in this component
*/
export default class HideButton extends React.Component {
  /**
  * Handles click by hiding the BrowsingSection
  */
  handleClick = () => {
    this.props.toggleBrowsingSection();
  };

  /**
  * Renders a button for hiding the BrowsingSection
  */
  render () {
    let arrow = "";
    let up = false;
    if (this.props.layout === "videoBottom") {
      up = !up;
    }
    if (this.props.browsingSection) {
      up = !up;
    }
    if (up) {
      arrow = "\u25B2";
    } else {
      arrow = "\u25BC";
    }
    return (
      <button id='browsing-hide-button' onClick={this.handleClick}>{arrow}</button>
    );
  }
}
