import React from "react";

import "./ChannelTab.css";

export default class ChannelTab extends React.Component {
  constructor (props) {
    super(props);
    this.change = this.change.bind(this);
  }

  change () {
    if (this.props.currentView.get("viewType") === "date") {
      if (this.props.currentView.get("channel") !== this.props.number) {
        this.props.changeView(this.props.currentView.set("channel", this.props.number).toJS());
      }
    } else if (this.props.currentView.get("viewType") === "search") {
      this.props.changeView({
        viewType: "date",
        channel: this.props.number,
        date: this.props.previousDate
      });
    }
  }

  render () {
    let cls = "channelTab";
    if (this.props.currentView.get("viewType") === "date" &&
      this.props.currentView.get("channel") === this.props.number) {
      cls += " selected";
    }
    return (
      <div className={cls} onClick={this.change}>
        <img className={"channelThumb"} src={this.props.thumb} alt={`${this.props.display} Logo`}/>
        <p className='noselect'>{this.props.display}</p>
      </div>
    );
  }
}
