import {connect} from "react-redux";
import {actions} from "redux/modules/CommonActions";
import {actions as BrowsingSectionActions} from "redux/modules/BrowsingSectionDuck";
import {actions as ScheduleActions} from "redux/modules/ScheduleDuck";
import {actions as VideoPlayerActions} from "redux/modules/VideoPlayerDuck";

import BrowsingSection from 'components/BrowsingSection/BrowsingSection';

const mapDispatchToProps = {
  ...BrowsingSectionActions,
  ...ScheduleActions,
  getTotalItems: BrowsingSectionActions.getTotalItemsAsync,
  navigate: actions.navigateAsync,
  playVideo: actions.playVideoSync,
  playVideoAsync: actions.playVideoAsync,
  playLive: actions.playLiveStream,
  populateQueue: VideoPlayerActions.populateQueue,
  setQueueIndex: VideoPlayerActions.setQueueIndex,
  setLoop: VideoPlayerActions.setQueueLoop,
  retrieveItems: ScheduleActions.retrieveItemsAsync
}

const mapStateToProps = (state) => ({
  data: state.BrowsingSectionDuck,
  showViewCounts: state.VideoInfoDuck.get("showViewCounts"),
  showLiveViews: state.VideoInfoDuck.get("showLiveViews"),
  scheduleData: state.ScheduleDuck,
  loop: state.VideoPlayerDuck.get("loop"),
  asyncCallsOut: state.LoaderDuck.get("asyncCallsOut")
})

export default connect(mapStateToProps, mapDispatchToProps)(BrowsingSection)
