import React from "react";

import "./BreadCrumbs.css";

/**
* Bar used to display navpath, go up navigation directories, or hide the browsing section
* @prop {array} crumbs The full navpath of the BrowsingSection in an array
* @prop {array} navPath The navpath of the BrowsingSection. I don't know what the difference is?
* @prop {boolean} nonav Whether the BrowsingMenu is displayed or not
* @prop {function} navigate Used to navigate the BrowsingSection
* @prop {function} toggleBrowsingSection Used to show/hide the whole browsing section
*/
export default class BreadCrumbs extends React.Component {
  shouldComponentUpdate (nextProps) {
    return (this.props.crumbs !== nextProps.crumbs ||
            this.props.navPath !== nextProps.navPath);
  }

  /**
  * Renders a bar that displays the current page that the BrowsingSection is navigated to. Has a button for going up
  * one level in terms of navigation, as well as for hiding the BrowsingSection.
  */
  render () {
    let backArrow = "\u2770";
    let label = this.props.crumbs.get(this.props.crumbs.size - 1);
    let capLabel = "";
    let regex = /^playlists\/(.+)\.m3u8$/;
    let backButton = "";
    if (!this.props.nonav) {
      if (this.props.crumbs.size > 1 && this.props.crumbs.get(0) !== "search") {
        backButton = <div id='backButton' onClick={() => {
          if (this.props.crumbs && this.props.crumbs.size > 1) {
            this.props.navigate(this.props.navPath.slice(0, this.props.crumbs.size - 1).toJS());
          }
        }}>{backArrow}</div>;
      }
    }
    if (regex.test(label)) {
      label = regex.exec(label)[1];
    }
    if (label && label.length > 1) {
      capLabel = label.charAt(0).toUpperCase() + label.slice(1);
    } else if (label && label.length === 1) {
      capLabel = label.toUpperCase();
    }
    return (
      <div id='browsing-label-area'>
        {backButton}
        <div className='noselect' id='browsing-label'>{capLabel}</div>
      </div>
    );
  }
}
