import "./Paginator.css"
import PaginatorButton from "./PaginatorButton.js";
import PaginatorJumpButton from "./PaginatorJumpButton.js";

var React = require("react");

export default class Paginator extends React.Component {
  constructor (props) {
    super(props);
    this.state = {count: window.innerWidth / 32};
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount () {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount () {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize () {
    this.setState(prevState => ({count: window.innerWidth / 32}));
  }

  shouldComponentUpdate (newprops, newstate) {
    return (this.props.pages !== newprops.pages ||
                this.props.perPage !== newprops.perPage ||
                this.props.pageNumber !== newprops.pageNumber ||
                this.state.count !== newstate.count);
  }

    nextPage = () => {
      if (this.props.pageNumber < this.props.pages || !this.props.pages) {
        this.props.setCategory("pageNumber", this.props.pageNumber + 1);
      }
    };

    previousPage = () => {
      if (this.props.pageNumber > 1) {
        this.props.setCategory("pageNumber", this.props.pageNumber - 1);
      }
    };

    changePerPage = () => {
      this.props.setCategory("perPage", this.refs.perPage.value);
    };

    render () {
      var pageButtons = [];
      if (this.props.pages) {
        let total = this.state.count;
        total = total - 7;
        let aheadCount = Math.floor(total / 2);
        let beforeCount = Math.floor(total / 2);
        if (this.props.pageNumber + aheadCount > this.props.pages) {
          let difference = (this.props.pageNumber + aheadCount) - this.props.pages;
          beforeCount = beforeCount + difference;
          aheadCount = aheadCount - difference;
        } else if ((this.props.pageNumber - beforeCount) < 1) {
          let difference = (beforeCount - this.props.pageNumber) - 1;
          aheadCount = aheadCount + difference;
          beforeCount = beforeCount - difference;
        }
        var i = this.props.pageNumber - beforeCount;
        if (i > 1) {
          pageButtons.push(<PaginatorButton currentNumber={this.props.pageNumber}
            number={1}
            key={"pBut1"}
            setCategory={this.props.setCategory} />);
          if (i > 2) {
            pageButtons.push(<PaginatorJumpButton key={"pJumpA"}
              setCategory={this.props.setCategory} />);
          }
        }
        while (i <= this.props.pageNumber + aheadCount) {
          if (i > 0 && i <= this.props.pages) {
            pageButtons.push(<PaginatorButton currentNumber={this.props.pageNumber}
              number={i}
              key={"pBut" + i}
              setCategory={this.props.setCategory} />);
          }
          i++;
        }
        if (i <= this.props.pages) {
          if (i <= this.props.pages - 1) {
            pageButtons.push(<PaginatorJumpButton key={"pJumpB"}
              setCategory={this.props.setCategory} />);
          }
          pageButtons.push(<PaginatorButton currentNumber={this.props.pageNumber}
            number={this.props.pages}
            key={"pBut" + this.props.pages}
            setCategory={this.props.setCategory} />);
        }
      }
      return (
        <div className='paginatorDiv'>
          <button key={"pBack"} onClick={this.previousPage}>&#9668;</button>
          {pageButtons}
          <button key={"pNext"} onClick={this.nextPage}>&#9658;</button>
        </div>
      );
    }
}
