import React from "react";

import {
  FacebookShareButton,
  GooglePlusShareButton,
  TwitterShareButton
} from "react-share";

import "./ShareModal.css";

/**
* Modal that provides sharing functionality
* @prop {boolean} display Whether to display this modal or not.
* @prop {string} rpath The rpath of the current video or live stream.
* @prop {function} toggleShareModal Function used to hide this modal.
*/
export default class ShareModal extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = {embedWidth: 560, embedHeight: 315};
  }

  /**
  * Stops an event from propogating.
  * @param {object} e The event to stop from propogating.
  */
  stopBubble = (e) => {
    e.stopPropagation();
  };

  handleCopy = (ref) => {
    let input = this.refs[ref];
    input.focus();
    input.select();
  };

  changeEmbedSize = (e) => {
    let name = e.target.name;
    this.setState({[name]: e.target.value});
  }

  /**
  * Renders a modal that provides options for sharing the current video/live stream. Can be
  * shown or hid.
  */
  render () {
    let cls = "modal";
    let url = encodeURI(window.location.origin + "/vod/index.php/" + this.props.rpath);
    let newUrl = "";
    let embed = "";
    if (this.props.rpath.startsWith("@live:")) {
      let liveNum = this.props.rpath.split(":");
      liveNum = liveNum[liveNum.length - 1];
      newUrl = encodeURI(window.location.origin + "/vod/?live=" + liveNum);
    } else {
      let uuid = /[0-9A-Fa-f]{8}-(?:[0-9A-Fa-f]{4}-){3}[0-9A-Fa-f]{12}/.exec(this.props.rpath);
      if (uuid) {
        newUrl = encodeURI(window.location.origin + "/vod/?video=" + uuid[0]);
      }
    }
    if (newUrl) {
      embed = "<iframe src=" + newUrl + "&view=7&noautoplay=1" +
      " width=" + this.state.embedWidth +
      " height=" + this.state.embedHeight +
      " allowfullscreen></iframe>";
    }
    if (this.props.display) {
      cls = cls + " show";
    }
    return (
      <div className={cls} onClick={this.props.toggleShareModal}>
        <div className='modalContent' onClick={this.stopBubble}>
          <button className='modalCloseButton' onClick={this.props.toggleShareModal}>&times;</button>
          <label className='shareLabel'>Direct Link</label>
          <div id='shareModalDirect'>
            <input className='shareText' type='text' ref='directLink' readOnly value={newUrl} />
            <button className='shareBtn' onClick={() => { this.handleCopy("directLink"); }}>Select</button>
          </div>
          <label className='shareLabel'>Social Media Link</label>
          <div id='shareModalOld'>
            <input className='shareText' type='text' ref='oldLink' readOnly value={url} />
            <button className='shareBtn' onClick={() => { this.handleCopy("oldLink"); }}>Select</button>
          </div>
          <label className='shareLabel'>HTML Embed</label>
          <div id='shareModalEmbedSize'>
            <label className='shareInnerLabel'>Width</label>
            <input className='shareText'
              type='number'
              value={this.state.embedWidth}
              onChange={this.changeEmbedSize}
              name='embedWidth' />
            <label className='shareInnerLabel'>Height</label>
            <input className='shareText'
              type='number'
              value={this.state.embedHeight}
              onChange={this.changeEmbedSize}
              name='embedHeight' />
          </div>
          <div id='shareModalEmbed'>
            <input className='shareText' type='text' ref='embedCode' readOnly value={embed} />
            <button className='shareBtn' onClick={() => { this.handleCopy("embedCode"); }}>Select</button>
          </div>
          <label className='shareLabel'>Share on Social Media</label>
          <div id='shareModalSocialMedia'>
            <FacebookShareButton url={url}>
              <img src={"1485918558_online_social_media_facebook.svg"} className='socialIcon' alt="Facebook"/>
            </FacebookShareButton>
            <TwitterShareButton url={url}>
              <img src={"1485918560_twitter_online_social_media.svg"} className='socialIcon' alt="Twitter"/>
            </TwitterShareButton>
            <GooglePlusShareButton url={url}>
              <img src={"1485918564_online_social_media_google_plus.svg"} className='socialIcon' alt="Google +"/>
            </GooglePlusShareButton>
          </div>
        </div>
      </div>
    );
  }
}
