import React from "react";
import BreadCrumbs from "./BreadCrumbs.js";
import LinkItem from "./LinkItem.js";
import ListSchedule from "./EmbeddedSchedule/ListSchedule.js";
import InfoModal from "./InfoModal.js";
import Paginator from "./Paginator.js";
import LoopToggle from "./LoopToggle.js";
import Spinner from "react-spinkit";

import "./BrowsingSection.css";

/**
* Component for diplaying the contents of the widget, such as videos, playlists, programs, and live schedules.
* @prop {object} data State from the BrowsingSectionDuck
* @prop {object} liveScheduleData State from the LiveScheduleDuck
* @prop {number} asyncCallsOut The number of outgoing asynchronous calls
* @prop {string} layout The layout of the widget.
* @prop {function} navigate Used to navigate the BrowsingSection
* @prop {function} playVideo Used to play a video
* @prop {function} playLive Used to play a live stream
* @prop {function} populateQueue Used to populate the video queue for autoplay
* @prop {function} setQueueIndex Used to set the current index within the autoplay queue
* @prop {function} scrollTime Used to scroll the current time of the live schedules
* @prop {function} scrollDate Used to scroll the current date of the live schedules
* @prop {function} selectSchedule Used to select what time segment of the live schedules to display
* @prop {function} populateSchedule Used to populate the whole live schedules
* @prop {function} toggleLiveModal Used to show/hide the multi-item live modal
* @prop {function} setLiveModalList Used to set the item list for the multi-item live modal
* @prop {function} setLiveSegments Used to set the number of segments displayed on the live schedules
* @prop {function} toggleInfoModal Used to show/hide the video info modal.
* @prop {function} changeTimeScale Used to set the time scale of the live schedules.
* @prop {function} toggleBrowsingSection Used to show/hide the whole browsing section
*/
export default class BrowsingSection extends React.PureComponent {
  handlePaging = (what, toWhat) => {
    if (what === "pageNumber") {
      this.props.navigate(this.props.data.get("navPath").toJS(), true, toWhat);
    }
  }

  componentWillUpdate (nextProps) {
    if (this.itemContainer &&
    this.props.data.getIn(["displayItems"].concat(this.props.data.get("navPath").toJS(), "paging", "page")) !==
    nextProps.data.getIn(["displayItems"].concat(this.props.data.get("navPath").toJS(), "paging", "page"))) {
      this.itemContainer.scrollTop = 0;
    }
  }

  changeScheduleView = (newView) => {
    if(newView.viewType === "date") {
      this.props.navigate(["live", `ch${newView.channel}`])
    } else {
      this.props.navigate(["live"])
    }
    this.props.changeView(newView)
  }

  /**
  * Renders a section for navigating the contents of the widget.
  * Displays either a list of LinkItems or the LiveSchedule.
  * Can be hidden. Also has a bar at the top showing the current navigation page, as well as containing the hide button.
  * Finally, the section has a spinner for showing if there are outgoing asynchronous call to the backend.
  */
  render () {
    let navInfo = this.props.data.getIn(["displayItems"].concat(this.props.data.get("navPath").toJS()));
    let page, start, displayItems;
    if (navInfo) {
      page = navInfo.getIn(["paging", "page"]);
      start = (page - 1) * this.props.data.get("perPage");
      displayItems = navInfo.get("list");
    }
    if (displayItems &&
    (this.props.data.getIn(["navPath", 0]) !== "programs" ||
    this.props.data.get("navPath").size > 1)) {
      displayItems = displayItems.slice(start, start + this.props.data.get("perPage"));
    }
    let contents = [];
    let data = null;
    let spin = this.props.asyncCallsOut ? "" : "hidden";
    let id = "browsing-section";
    let cls = "";
    let breadCrumbs = "";
    let paginator = "";
    let queueControls = "";
    if (this.props.data.getIn(["navPath", 0]) === "live") {
      contents = <ListSchedule data={this.props.scheduleData}
        changeView={this.changeScheduleView}
        playVideoAsync={this.props.playVideoAsync}
        playLive={this.props.playLive}
        showLiveViews={this.props.showLiveViews}
        layout={this.props.layout} />;
    } else if (this.props.data.getIn(["navPath", 0]) && displayItems) {
      let items = displayItems.map((item, index) => {
        if (item && !item.get("hidden")) {
          return <LinkItem item={item} key={item.get("key")}
            index={start + index}
            navigate={this.props.navigate}
            playVideo={this.props.playVideo}
            populateQueue={this.props.populateQueue}
            setQueueIndex={this.props.setQueueIndex}
            toggleInfoModal={this.props.toggleInfoModal}
            navPath={this.props.data.get("navPath")}
            showViewCounts={this.props.showViewCounts}
            playingItem={this.props.data.get("playingItem")}
            playcounts={this.props.data.get("playcounts")}/>;
        } else {
          return null;
        }
      });
      if (page &&
      (this.props.data.getIn(["navPath", 0]) !== "programs" ||
      this.props.data.get("navPath").size > 1)) {
        let pageCount = Math.ceil(navInfo.getIn(["paging", "total"]) / this.props.data.get("perPage"));
        if (!(pageCount === 1 || pageCount === 0)) {
          paginator = <Paginator pages={pageCount}
            pageNumber={page}
            perPage={this.props.data.get("perPage")}
            setCategory={this.handlePaging}
            key={"paginator"} />;
        }
      }
      contents = <div id='browsing-items'>{items}</div>;
    }
    if (this.props.data.get("hidden")) {
      id = id + "-hidden";
    }
    if (this.props.layout && this.props.layout === "schedule") {
      cls = cls + " schedule";
    }
    if (this.props.data.get("infoFile") >= 0 && displayItems.get(this.props.data.get("infoFile"))) {
      data = displayItems.get(this.props.data.get("infoFile"));
    }
    if (!this.props.layout || this.props.layout !== "schedule") {
      breadCrumbs = <BreadCrumbs crumbs={this.props.data.get("breadCrumbs")}
        navPath={this.props.data.get("navPath")}
        navigate={this.props.navigate}
        nonav={!(this.props.data.get("backButtonShow"))}
        layout={this.props.layout}
        toggleBrowsingSection={this.props.toggleBrowsingSection} />;
    }
    if (this.props.data.get("navPath").size > 1 &&
      (this.props.data.getIn(["navPath", 0]) === "programs" ||
      this.props.data.getIn(["navPath", 0]) === "playlists")) {
      queueControls = (
        <div id='queueControls'>
          <LoopToggle loop={this.props.loop} setLoop={this.props.setLoop} />
        </div>
      );
    }
    return (
      <div id={id} className={cls}>
        <div id={"browsing-spinner"} className={spin}>
          <Spinner name='line-spin-fade-loader' fadeIn='none' />
        </div>
        {breadCrumbs}
        <div ref={(container) => { this.itemContainer = container; }} id={"browsing-items-container"}>
          {queueControls}
          {contents}
          {paginator}
        </div>
        <InfoModal toggleInfoModal={this.props.toggleInfoModal}
          playVideo={this.props.playVideo}
          display={this.props.data.get("display")}
          showViewCounts={this.props.showViewCounts}
          data={data} />
      </div>
    );
  }
}
