import React from "react";
import Agenda from "./Agenda.js";

import "./Sidebar.css"

/**
* Video sidebar, currently used for video agendas.
* @prop {object} data State from the SidebarDuck.
* @prop {function} gotoTime Passed to Agenda.
*/
export default class Sidebar extends React.Component {
  shouldComponentUpdate (nextProps) {
    return (this.props.data !== nextProps.data);
  }

  /**
  * Renders a sidebar that appears when needed and hides itself otherwise. Currently
  * appears when a video has an agenda.
  */
  render () {
    let style = {display: "none"};
    if (this.props.data.get("visible")) {
      style.display = "block";
    }
    return (
      <div id='video-sidebar' style={style}>
        <Agenda agenda={this.props.data.get("agenda")}
          time={this.props.data.get("time")}
          highlight={this.props.data.get("highlight")}
          gotoTime={this.props.gotoTime} />
      </div>
    );
  }
}
