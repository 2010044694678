import React from "react";
import AgendaItem from "./AgendaItem.js";

import "./Agenda.css";

/**
* Agenda for the currently playing video.
* @prop {object} agenda The agenda of the current video.
* @prop {number} time The current time of the video.
* @prop {array} highlight The address of the agenda item to highlight.
*/
export default class Agenda extends React.Component {
  shouldComponentUpdate (nextProps) {
    return (this.props.agenda !== nextProps.agenda ||
            this.props.time !== nextProps.time);
  }

  /**
  * Renders a container with a label that says "Agenda", and containing one or more AgendaItems.
  * AgendaItems are passed certain props depending on the existance of children or items that occur after them.
  * AgendaItems with a child or next item are passed the endTime prop,
  *   which indicates when the next item or child occurs,
  *   and AgendaItems with both a child and next item are passed the nextTime prop,
  *   which indicates when the next item occurs
  *   (in this situation endTime always indicates the child).
  */
  render () {
    let items = [];
    if (this.props.agenda.get("items")) {
      items = this.props.agenda.get("items").map((item, index) => {
        let highlight = null;
        if (this.props.highlight.get(0) === index) {
          highlight = this.props.highlight.slice(1);
        }
        if (item.get("items") && item.getIn(["items", 0, "time"])) {
          if (this.props.agenda.getIn(["items", index + 1])) {
            return <AgendaItem item={item}
              key={item.get("key")}
              gotoTime={this.props.gotoTime}
              time={this.props.time}
              endTime={item.getIn(["items", 0, "time"])}
              highlight={highlight || null}
              nextTime={this.props.agenda.getIn(["items", index + 1, "time"])} />;
          } else {
            return <AgendaItem item={item}
              key={item.get("key")}
              gotoTime={this.props.gotoTime}
              time={this.props.time}
              highlight={highlight || null}
              endTime={item.getIn(["items", 0, "time"])} />;
          }
        } else if (this.props.agenda.getIn(["items", index + 1])) {
          return <AgendaItem item={item}
            key={item.get("key")}
            gotoTime={this.props.gotoTime}
            time={this.props.time}
            highlight={highlight || null}
            endTime={this.props.agenda.getIn(["items", index + 1, "time"])} />;
        } else {
          return <AgendaItem item={item}
            key={item.get("key")}
            gotoTime={this.props.gotoTime}
            time={this.props.time}
            highlight={highlight || null} />;
        }
      });
    }
    return (
      <div id='video-agenda'>
        <div id='video-agenda-title'>Agenda</div>
        {items}
      </div>
    );
  }
}
