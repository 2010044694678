var React = require("react");

export default class PaginatorButton extends React.Component {
  shouldComponentUpdate (newprops) {
    return (this.props.currentNumber !== newprops.currentNumber ||
               this.props.number !== newprops.number);
  }

    handlePageChange = () => {
      this.props.setCategory("pageNumber", this.props.number);
    };

    render () {
      var stylez = {};
      if (this.props.currentNumber === this.props.number) {
        stylez = {
          backgroundColor: "lightgrey"
        };
      }
      return (
        <button onClick={this.handlePageChange} style={stylez}>{this.props.number}</button>
      );
    }
}
