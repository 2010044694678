import React from "react";
import Schedule from "./Schedule.js";
import SearchBar from "./SearchBar.js";
import ScheduleHeader from "./ScheduleHeader.js";
import ChannelTabs from "./ChannelTabs.js";

import "./ListSchedule.css"

export default class ListSchedule extends React.Component {
  shouldComponentUpdate (nextProps) {
    return nextProps.data !== this.props.data;
  }

  render () {
    let startDate = null;
    let endDate = null;
    let available = false;
    let currentChannel = this.props.data.getIn(["configData", "" + this.props.data.getIn(["currentView", "channel"])]);
    let viewers = 0;
    if (currentChannel) {
      let livePath = /\/live\/ch\d+\.m3u8/.exec(currentChannel.getIn(["live", "hls"]));
      if (livePath && livePath[0]) {
        viewers = this.props.data.getIn(["liveViewers", livePath[0]]) || 0;
      }
    }
    let channelTabs = <ChannelTabs config={this.props.data.get("configData")}
      previousDate={this.props.data.get("previousDate")}
      currentView={this.props.data.get("currentView")}
      changeView={this.props.changeView}
    />;
    if (this.props.data.getIn(["currentView", "viewType"]) === "date") {
      startDate = this.props.data.getIn(["serverData",
        this.props.data.getIn(["currentView", "channel"]),
        "report",
        "start unix time"]) * 1000;
      endDate = this.props.data.getIn(["serverData",
        this.props.data.getIn(["currentView", "channel"]),
        "report",
        "end unix time"]) * 1000;
    }
    if (this.props.data.getIn(["serverData", "" + this.props.data.getIn(["currentView", "channel"])])) {
      available = true;
    }
    if (this.props.layout === "schedule" && !this.props.data.get("embedChannelControl")) {
      channelTabs = "";
    }
    return (
      <div id='listwrapper'>
        {channelTabs}
        <div id='scheduleContainer'>
          <SearchBar changeView={this.props.changeView} />
          <ScheduleHeader view={this.props.data.get("currentView")}
            embedLivePlay={this.props.data.get("embedLivePlay")}
            startDate={startDate}
            endDate={endDate}
            showLiveViews={this.props.showLiveViews}
            previousDate={this.props.data.get("previousDate")}
            previousChannel={this.props.data.get("previousChannel")}
            changeView={this.props.changeView}
            currentChannel={currentChannel}
            viewers={viewers}
            available={available}
            playLive={this.props.playLive}
            layout={this.props.layout}
          />
          <Schedule displaySchedule={this.props.data.get("displaySchedule")}
            embedVideoPlay={this.props.data.get("embedVideoPlay")}
            showSeconds={this.props.data.get("showSeconds")}
            layout={this.props.layout}
            playVideoAsync={this.props.playVideoAsync}
          />
        </div>
      </div>
    );
  }
}
