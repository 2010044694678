import React from "react";
import OuterWindowVideoTop from "./OuterWindowVideoTop.js";
import OuterWindowVideoBottom from "./OuterWindowVideoBottom.js";
import OuterWindowSchedule from "./OuterWindowSchedule.js";

import "./OuterWindow.css";

/**
* The outer frame of the entire widget.
* @prop {string} video video tag from the url. Determines video to play upon loading the widget.
* @prop {string} live live tag from the url. Determines live stream to play upon loading widget.
*   Mutually exclusive with video, if both are present video takes precedence.
* @prop {number} view view tag from the url. Binary hash used to determine what components to hide.
* @prop {boolean} noautoplay noautoplay tag from the url. If set to true, video will not automatically start.
* @prop {string} nav nav tag from the url. Determines initial navigation for the BrowsingSection on load.
* @prop {number} volume volume tag from the url. Determines initial volume of videos, on a scale from 0.0 to 1.0.
*/
export default class OuterWindow extends React.Component {
  /**
  * Called for initialization when widget first loads. Loads data from config.js, initializes state of URLDuck from url,
  * and converts previous version url to current version url.
  */
  componentWillMount () {
    this.props.initializeConfig(window.location.origin)
      .then(this.handleQueryParameters)
      .then(() => {
        return Promise.all([
          // Handle playing initial video/live stream
          this.handlePlayInitialVideo(),
          // Navigate browsing section based on url
          this.handleInitialNavigation().then(this.props.getTotalItems),
          // Get live viewership
          this.props.updateViewers(),
          // Get data for first schedule then set up schedule tab
          this.handleScheduleSetup(),
          // Get playcounts
          this.handlePlaycounts()
        ]);
      });
    this.liveInterval = setInterval(this.props.updateViewers, 30000);
  }

  componentWillUnmount () {
    if (this.liveInterval) {
      clearInterval(this.liveInterval);
    }
  }

  /**
   * Handler for parsing incoming query string and hash parameters and
   *  setting various settings of the widget based on the values of those
   *  parameters
   */
  handleQueryParameters = async () => {
    let {urlParams,
      setQueueLoop,
      videoShouldAutoplay,
      hideBackButton,
      updateURLParams,
      updateURL} = this.props
    let paramObject = {};
    Object.entries(urlParams).forEach(([key, val]) => {
      switch(key) {
        case "loop":
          if(val === "1") {
            paramObject[key] = 1;
          }
          break;
        case "video":
          if(paramObject.live) {
            delete paramObject.live;
          }
          paramObject[key] = val;
          break;
        case "live":
          if(!paramObject.video) {
            paramObject[key] = val;
          }
          break;
        case "nav":
          let split = s => (([x, ...y]) => y.length ? [x, y.join("/")] : [x])(s.split("/"));
          paramObject[key] = split(decodeURIComponent(val));
          break;
        case "volume":
          if(val !== 1.0) {
            paramObject[key] = val;
          }
          break;
        default:
          paramObject[key] = val;
          break;
      }
    })
    if (paramObject.noautoplay === "1") {
      videoShouldAutoplay(false);
    }
    if (paramObject.loop === 1) {
      setQueueLoop(true);
    }
    if (paramObject.view && !(paramObject.view & 8) &&
      paramObject.nav && paramObject.nav.length > 1) {
      hideBackButton();
    }
    updateURLParams(paramObject);
    updateURL();
  }

  /**
   * Plays the initial video/live stream based on the current url parameters
   */
  handlePlayInitialVideo = async () => {
    let {urlParams,
      playVideoAsync,
      ScheduleDuck,
      playLive} = this.props
    if (urlParams.view && !(urlParams.view & 1)) {
      return
    } else if (urlParams.video) {
      let info = {
        guid: urlParams.video,
        in: urlParams.in,
        out: urlParams.out
      };
      return playVideoAsync(info)
    } else if (urlParams.live) {
      let chan = urlParams.live.match(/\d+$/)[0];
      let chConf = ScheduleDuck.getIn(["configData", "" + chan]);
      if (chConf) {
        let livePlayData = {filename: chConf.get("filename"),
          live: chConf.get("live").toJS(),
          poster: chConf.get("thumb"),
          id: "ch" + chan};
        return playLive(livePlayData);
      } else {
        let err = "Error: Settings for channel " +
          chan +
          "are not in the config file. Available channels are: ";
        ScheduleDuck.get("configData").forEach((element, index) => {
          err += ("\n     " + index);
        });
        console.error(err);
      }
    }
  }

  /**
   * Navigates the browsing section based on the current url parameters
   */
  handleInitialNavigation = async () => {
    let {urlParams,
      navigate,
      navigateSync,
      getTotalItems,
      loadVideoData} = this.props
    if (urlParams.schedule) {
      return navigate(["live"]);
    } else if (urlParams.view && !(urlParams.view & 16)) {
      return;
    } else if (urlParams.nav) {
      let split = s => (([x, ...y]) => y.length ? [x, y.join("/")] : [x])(s.split("/"));
      let navSplit = split(decodeURIComponent(urlParams.nav));
      await navigate(navSplit)
      if(navSplit[0] === "search") {
        return getTotalItems(navSplit[1])
      }
    } else {
      navigateSync(["recent"]);
      return loadVideoData(["recent"]);
    }
  }

  /**
   * Handles initial setup/loading of schedule
   */
  handleScheduleSetup = async () => {
    let {urlParams,
      ScheduleDuck,
      retrieveItems,
      changeView} = this.props
    let element = ScheduleDuck.get("configData").first();
    let schedule = urlParams.schedule
    if(!schedule && urlParams.nav && urlParams.nav.startsWith ("live/")) {
      let split = s => (([x, ...y]) => y.length ? [x, y.join("/")] : [x])(s.split("/"));
      let navSplit = split(decodeURIComponent(urlParams.nav));
      if(navSplit[1]) {
        schedule = navSplit[1]
      }
    }
    if (schedule) {
      let schedParam = ScheduleDuck.get("configData").find((sched) => {
        return (sched.get("spath") && /:(ch[0-9]+)$/.exec(sched.get("spath"))[1] === schedule);
      });
      if (schedParam) {
        element = schedParam;
      }
    }
    if (element && element.get("spath") && (!urlParams.view || urlParams.view & 16 || schedule)) {
      let chan = /:ch([0-9]+)$/.exec(element.get("spath"))[1];
      try {
        await retrieveItems(chan)
        let dateTime = Date.now();
        if (dateTime < (ScheduleDuck.getIn(["serverData",
          chan,
          "report",
          "start unix time"]) * 1000) ||
          dateTime > (ScheduleDuck.getIn(["serverData",
            chan,
            "report",
            "end unix time"]) * 1000)) {
          dateTime = ScheduleDuck.getIn(["serverData",
            chan,
            "report",
            "unix time"]) * 1000;
        }
        let date = new Date(dateTime);
        date = (new Date(date.getFullYear(), date.getMonth(), date.getDate())).valueOf();
        changeView({viewType: "date", date, channel: chan});
      } catch {
        changeView({viewType: "date", date: Date.now(), channel: chan});
      };
    }
  }

  /**
   * Loads video playcounts
   */
  handlePlaycounts = async () => {
    let {retrievePlaycounts} = this.props
    return retrievePlaycounts();
  }

  /**
  * Main render function. Renders a layout component for the widget,
  * which is selected based on the layout property of the OuterWindowDuck.
  */
  render () {
    if (this.props.urlParams.schedule) {
      return <OuterWindowSchedule view={this.props.urlParams.view}
        hidden={!this.props.VideoPlayerDuck.get("videoURL")} />;
    } else {
      switch (this.props.OuterWindowDuck.get("layout")) {
        case "videoLeft":
          // videoLeft is not yet implemented,
          //  and should not be used until a top-down version of the live schedule can be made.
          return null;
        case "videoBottom":
          return <OuterWindowVideoBottom view={this.props.urlParams.view}
            hidden={this.props.BrowsingSectionDuck.get("hidden")} />;
        case "videoTop":
          return <OuterWindowVideoTop view={this.props.urlParams.view}
            hidden={this.props.BrowsingSectionDuck.get("hidden")} />;
        default:
          return <div/>;
      }
    }
  }
}
