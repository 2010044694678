import {connect} from "react-redux";
import {actions as VideoPlayerActions} from "redux/modules/VideoPlayerDuck";

import Sidebar from "components/Sidebar/Sidebar"

const mapDispatchToProps = {
  gotoTime: VideoPlayerActions.gotoTime
}

const mapStateToProps = (state) => ({
  data: state.SidebarDuck
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
