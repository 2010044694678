import React from "react";

import "./VideoInfoText.css";

/**
* Text label for the video info bar.
* @prop {string} title The text to display in the label.
*/
export default class VideoInfoText extends React.PureComponent {
  /**
  * Renders a span with the given title.
  */
  render () {
    return (
      <div id='video-info-title'>
        <div>{this.props.title}</div>
        {this.props.showViews ?
          <div id='video-info-views'>{this.props.viewLogo || ""}</div> :
          null}
      </div>
    );
  }
}
