import React from "react";

import "./ClientLogo.css";

/**
* Image that display's the station logo of this widget. Shows WidgetInfoModal when clicked on.
* @prop {string} logo The url of the logo.
* @prop {function} toggleWidgetInfoModal Used to show the WidgetInfoModal when clicked on.
*/
export default class ClientLogo extends React.PureComponent {
  /**
  * Renders a client logo that can be clicked on to show a widget info modal.
  */
  render () {
    return (
      <img src={this.props.logo} id='video-info-logo' onClick={this.props.toggleWidgetInfoModal} alt=""/>
    );
  }
}
