import React from "react";

import VideoPlayer from "./VideoPlayer.js";
import VideoControls from "./VideoControls.js";

import "./VideoSection.css"

/**
* Container component for video player.
* @prop {object} data Passed through to VideoPlayer.
* @prop {number} volume Passed through to VideoPlayer.
* @prop {function} playNext Passed through to VideoPlayer.
* @prop {function} videoLoadStart Passed through to VideoPlayer.
* @prop {function} videoPlaycount Passed through to VideoPlayer.
* @prop {function} highlightAgenda Passed through to VideoPlayer.
* @prop {function} iframeVideoNotSupported Passed through to VideoPlayer.
* @prop {function} htmlVideoNotSupported Passed through to VideoPlayer.
*/
export default class VideoSection extends React.Component {
  constructor (props) {
    super(props);
    this.handleControls = this.handleControls.bind(this);
    this.handleShowControls = this.handleShowControls.bind(this);
    this.handleTimeScrub = this.handleTimeScrub.bind(this);
    this.endTimeScrub = this.endTimeScrub.bind(this);
    this.fullScreenEnabled = !!(document.fullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled ||
      document.webkitSupportsFullscreen ||
      document.webkitFullscreenEnabled ||
      document.createElement("video").webkitRequestFullScreen);
    this.state = {timeScrubbing: null,
      resume: false,
      timeChangeRequest: null,
      fullScreen: this.fullScreenEnabled ? isFullScreen() : false,
      showControlsTimeout: null,
      showControls: false,
      hoveringControls: false
    };
  }

  componentDidMount () {
    window.addEventListener("mousemove", this.handleTimeScrub);
    window.addEventListener("mouseup", this.endTimeScrub);
  }

  componentWillUnmount () {
    window.removeEventListener("mousemove", this.handleTimeScrub);
    window.removeEventListener("mouseup", this.endTimeScrub);
    if (this.state.showControlsTimeout) {
      clearTimeout(this.state.showControlsTimeout);
    }
  }

  handleTimeScrub (e) {
    if (this.state.timeScrubbing !== null) {
      let {timeScrubbing, timeChangeRequest} = this.state;
      let video = this.refs.videoPlayerRef.refs.videoDisplay;
      let pos = ((e.pageX - timeScrubbing.offsetLeft) / timeScrubbing.offsetWidth);
      pos = Math.min(Math.max(pos, 0.0), 1.0);
      this.props.setVideoData({currentTime: `${pos * video.duration}`});
      if (timeChangeRequest === null) {
        this.setState((state) => ({...state,
          timeChangeRequest: requestAnimationFrame(() => {
            video.currentTime = pos * video.duration;
            this.setState((state) => ({...state, timeChangeRequest: null}));
          })}));
      }
    }
  }

  endTimeScrub (e) {
    if (this.state.timeScrubbing !== null) {
      if (this.state.resume) {
        this.refs.videoPlayerRef.refs.videoDisplay.play();
      }
      this.setState({timeScrubbing: null, resume: false});
    }
  }

  shouldComponentUpdate (nextProps) {
    return (this.props.data !== nextProps.data);
  }

  handleControls (e) {
    if (this.refs.videoPlayerRef && this.refs.videoPlayerRef.refs.videoDisplay) {
      let video = this.refs.videoPlayerRef.refs.videoDisplay;
      switch (e.currentTarget.id) {
        case "video-play":
          if (video.paused || video.ended) {
            video.play();
          } else {
            video.pause();
          }
          return;
        case "video-progress-container":
          if(this.props.data.get("playMode") === 'live') {
            return
          }
          let pos = ((e.pageX - e.currentTarget.offsetLeft) / e.currentTarget.offsetWidth);
          video.currentTime = pos * video.duration;
          let resume = !(video.paused || video.ended);
          if (resume) {
            video.pause();
          }
          this.setState({timeScrubbing: e.currentTarget, resume});
          return;
        case "video-volume":
          video.volume = e.target.value;
          this.props.setVideoData({volume: video.volume});
          return;
        case "video-fullscreen":
          let fullScreen = this.handleFullScreen(this.refs.videoSectionRef);
          this.setState({fullScreen});
          return;
        case "video-captions":
          if (video.textTracks) {
            if (video.textTracks[0].mode === "showing") {
              video.textTracks[0].mode = "hidden";
            } else {
              video.textTracks[0].mode = "showing";
            }
            this.props.setVideoData({textTracks: video.textTracks, showCaptions: video.textTracks[0].mode});
          }
          break;
        default:
      }
    }
  }

  handleFullScreen (element) {
    if (isFullScreen()) {
      if (document.exitFullscreen) document.exitFullscreen();
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
      else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
      else if (document.msExitFullscreen) document.msExitFullscreen();
      return false;
    } else {
      if (element.requestFullscreen) element.requestFullscreen();
      else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
      else if (element.webkitRequestFullScreen) element.webkitRequestFullScreen();
      else if (element.msRequestFullscreen) element.msRequestFullscreen();
      return true;
    }
  }

  handleShowControls (timeout) {
    if (this.state.showControlsTimeout) {
      clearTimeout(this.state.showControlsTimeout);
    }
    this.setState((state) => ({
      ...state,
      showControlsTimeout: timeout > 0 ? setTimeout(() => {
        if (!this.state.hoveringControls) {
          this.setState((state) => ({...state, showControls: false}));
        }
      }, timeout) : null,
      showControls: true
    }));
  }

  handleHoveringControls = (hovering) => {
    this.setState((state) => ({
      ...state,
      hoveringControls: hovering
    }));
  }

  /**
  * Renders dumb container that contains video player, used for styling and layout.
  */
  render () {
    let display = "";
    let controls = "";
    let {resume, showControls, fullScreen, timeScrubbing} = this.state;
    let cls = "noselect";
    let downloadable = (!this.props.data.get("noDownload") && this.props.data.get("playMode") === "video");
    let scrubbing = timeScrubbing !== null;
    if (fullScreen && !showControls) {
      cls += " nocursor";
    }
    if (this.props.data.get("videoURL")) {
      display = <VideoPlayer data={this.props.data}
        volume={this.props.volume}
        nogui={false}
        ref='videoPlayerRef'
        playNext={this.props.playNext}
        videoLoadStart={this.props.videoLoadStartAsync}
        videoPlaycount={this.props.videoPlaycount}
        highlightAgenda={this.props.highlightAgenda}
        iframeVideoNotSupported={this.props.iframeVideoNotSupported}
        htmlVideoNotSupported={this.props.htmlVideoNotSupported}
        setVideoData={this.props.setVideoData} />;
      if (this.props.data.get("htmlVideoSupported")) {
        controls = <VideoControls handleControls={this.handleControls}
          downloadable={downloadable}
          videoURL={this.props.data.get("videoURL")}
          data={this.props.data.get("videoData")}
          playMode={this.props.data.get("playMode")}
          resume={resume}
          handleHoveringControls={this.handleHoveringControls}
          fullScreenEnabled={this.fullScreenEnabled}
          scrubbing={scrubbing}
          showControls={showControls} />;
      }
    } else {
      display = <div className='video-display' />;
    }
    return (
      <div id='video-section'
        className={cls}
        ref='videoSectionRef'
        onMouseMove={() => { this.handleShowControls(2000); }}
        onMouseOut={() => { this.handleShowControls(1000); }}>
        {display}
        {controls}
      </div>
    );
  }
}

var isFullScreen = function () {
  return !!(document.fullScreen ||
    document.webkitIsFullScreen ||
    document.mozFullScreen ||
    document.msFullscreenElement ||
    document.fullscreenElement);
};
