import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import {render} from 'react-dom';
import {Route} from 'react-router-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import store, {history} from "./store";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { ConnectedRouter, push } from "connected-react-router";
import DuckHelpers from "helpers/DuckHelpers.js";
import {actions as VideoPlayerActions} from "redux/modules/VideoPlayerDuck.js";

import OuterWindow from "containers/OuterWindow";

// Google Analytics
fetch("/api/v1/api/stats")
  .then(function (data) {
    if(data.ok &&
      data.headers.get("Content-Type").includes("application/json")) {
      data.json().then((data) => {
        if (data["google_id"] !== undefined) {
          ReactGA.initialize(data["google_id"]);
          ReactGA.pageview(window.location.pathname + window.location.search);
        }
      })
    }
  });

store.dispatch(push("" + DuckHelpers.urlBase() + "/" + window.location.search + window.location.hash));

// Google Cast
window["__onGCastApiAvailable"] = function (isAvailable) {
  if (isAvailable) {
    store.dispatch(VideoPlayerActions.ccInitialize());
  }
};

const target = document.querySelector('#root');

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div style={{ height: "100%" }}>
        <div style={{ height: "100%" }}>
          <Route path={"" + DuckHelpers.urlBase()}
            component={OuterWindow}
          />
        </div>
      </div>
    </ConnectedRouter>
    <div/>
  </Provider>,
  target
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
