import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import queryString from "query-string";
import {actions as VideoPlayerActions} from "../redux/modules/VideoPlayerDuck.js";
import {actions as SidebarActions} from "../redux/modules/SidebarDuck.js";

import VideoSection from 'components/VideoSection/VideoSection';

const mapDispatchToProps = {
  ...VideoPlayerActions,
  highlightAgenda: SidebarActions.highlightAgenda,
}

const mapStateToProps = (state) => ({
  data: state.VideoPlayerDuck,
})

let mergeProps = (stateProps, dispatchProps, ownProps) => {
  let searchProps = {};
  if (ownProps.location && ownProps.location.search) {
    searchProps = queryString.parse(ownProps.location.search);
  }
  return Object.assign({}, stateProps, dispatchProps, ownProps, searchProps);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(VideoSection));
