// vim: et sw=2 ts=2 sts=2
import {combineReducers} from "redux";
import {connectRouter} from 'connected-react-router'

import VideoPlayerDuck from "redux/modules/VideoPlayerDuck.js";
import VideoInfoDuck from "redux/modules/VideoInfoDuck.js";
import BrowsingSectionDuck from "redux/modules/BrowsingSectionDuck.js";
import SidebarDuck from "redux/modules/SidebarDuck.js";
import ScheduleDuck from "redux/modules/ScheduleDuck.js";
import OuterWindowDuck from "redux/modules/OuterWindowDuck.js";
import URLDuck from "redux/modules/URLDuck.js";
import LoaderDuck from "redux/modules/LoaderDuck.js";

export default (history) => combineReducers({
  router: connectRouter(history),
  // reducers go here.
  VideoPlayerDuck,
  VideoInfoDuck,
  BrowsingSectionDuck,
  SidebarDuck,
  ScheduleDuck,
  OuterWindowDuck,
  URLDuck,
  LoaderDuck
});
