import React from "react";

import "./SearchBar.css";

export default class SearchBar extends React.Component {
  constructor (props) {
    super(props);
    this.search = this.search.bind(this);
  }

  search () {
    this.props.changeView({viewType: "search", query: this.searchBar.value});
  }

  render () {
    return (
      <div id='searchBar'>
        <input id='searchBarInput' type='text'
          onKeyPress={(e) => { if (e.charCode === 13) { this.search(); } }}
          placeholder='Search'
          ref={(input) => { this.searchBar = input; }}
          aria-label="Search Schedule"
          aria-required={true}
        />
        <input id='searchBarButton'
          type='image'
          src='Magnifying_glass_icon.svg'
          alt='Search'
          onClick={this.search} />
      </div>
    );
  }
}
