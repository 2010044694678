import {actions as URLActions} from "./URLDuck.js";
import {actions as LoaderActions} from "./LoaderDuck.js";
import duckHelpers from "helpers/DuckHelpers.js";

import sampleConfig from "test/sample-values/config.json";

export const actions = {
  /**
  * Asynchronous navigation call, causes browsing area to navigate to the specified navpath.
  * @param {array} path The internal navpath to navigate to. Ex: ['programs', 'City Meetings'].
  * @param {number} port The port that the widget is served on.
  * @param {number} page The page to navigate to.
  *   If no value given, navigates to the page that that navpath was last on,
  *   or page 1 if this is the first time that navpath has been navigated to.
  */
  navigateAsync: (path, port, page = null) => {
    return async (dispatch, getState) => {
      let perPage = getState().BrowsingSectionDuck.get("perPage");
      let navInfo = getState().BrowsingSectionDuck.getIn(["displayItems"].concat(path));
      if (path[0] === "recent" || path[0] === "search") {
        if (!page) page = (navInfo && navInfo.getIn(["paging", "page"])) ? navInfo.getIn(["paging", "page"]) : 1;
        if (page < 1) page = 1;
        let start = ((page - 1) * perPage);
        if (!navInfo || !navInfo.getIn(["list", start])) {
          await dispatch(actions.loadVideoDataAsync(path, port, page));
        }
      } else if (path[0] === "programs" || path[0] === "playlists") {
        if (!navInfo || !navInfo.get("list")) {
          await dispatch(actions.loadVideoDataAsync(path, port, page));
        }
      }
      dispatch(URLActions.updateURL("nav", path));
      dispatch(actions.navigate(path, page));
    };
  },

  /**
  * Synchronous passthrough call made by navigateAsync.
  * @param {array} path The internal navpath to navigate to.
  * @param {number} page If set, changes to this page as well
  */
  navigate: (path, page = null) => {
    return {
      type: "NAVIGATE",
      path,
      page
    };
  },

  /**
   * Asynchronously loads video data from the server
   * @param {array} path The path of the browsing section to load data for
   * @param {number} port The port that the widget is served on
   * @param {number} page If set, will load the data for the given page.
   *  If no value given, it will load the data for page 1.
   */
  loadVideoDataAsync: (path, port, page = 1) => {
    return async (dispatch, getState) => {
      let perPage = getState().BrowsingSectionDuck.get("perPage");
      let navInfo = getState().BrowsingSectionDuck.getIn(["displayItems"].concat(path));
      let url = null;
      switch (path[0]) {
        case "recent":
          url = `/api/v1/api/assets?page=${page}&pagesize=${perPage}`
          break;
        case "programs":
          if (path[1]) {
            url = "/program/" + encodeURIComponent(path[1]);
          } else {
            url = "/program";
          }
          break;
        case "playlists":
          if (path[1]) {
            let playlistPath = path[1].split('/').pop()
            playlistPath = encodeURIComponent(playlistPath.replace(/\.m3u8/, ''))
            url = `/api/v1/api/playlist/${playlistPath}`
          } else {
            url = "/api/v1/api/playlist"
          }
          break;
        case "search":
          url = `/api/v1/api/assets?contains=*=${path[1]}&page=${page}&pagesize=${perPage}`
          break;
        default:
          break;
      }
      if (url) {
        dispatch(LoaderActions.reportOut());
        try {
          let data = await fetch(url);
          data = await data.json();
          if (data && data.length > 0) {
            dispatch(actions.loadVideoData(path, data, page));
          } else if (!navInfo || !navInfo.get("list")) {
            dispatch(actions.loadVideoData(path, data));
          } else {
            dispatch(actions.setTotal(path));
          }
        } catch (error) {
          console.error("Error Loading Assets: " + error);
        }
        dispatch(LoaderActions.reportIn());
      }
    };
  },

  /**
   * Passthrough function called by loadVideoDataAsync
   * @param {array} path The internal navpath the data was loaded for
   * @param {array} data The loaded data
   * @param {number} page The page the data was loaded for, if any
   */
  loadVideoData: (path, data, page = null) => {
    return {
      type: "LOAD-VIDEO-DATA",
      path,
      data,
      page
    };
  },

  /**
  * Set the total number of items at a given nav path,
  *   for used when the total is not already known and the end of the list is reached.
  * @param {array} path The internal navpath to set the total of.
  */
  setTotal: (path) => {
    return {
      type: "SET-TOTAL",
      path
    };
  },

  /**
  * Synchronous call to play a video. Not used for live streams.
  * @param {object} videoInfo Object containing information that can be used to identify the video.
  *   Right now, only videoInfo.guid is used.
  * @param {string} videoInfo.guid The guid of the video to be played.
  * @param {array}  videoInfo.agenda The agenda of the video if it has one
  * @param {object} videoInfo.meta The metadata of the video
  * @param {string} videoInfo.origname The original name of the video
  * @param {string} videoInfo.rpath The rpath of the video
  */
  playVideoSync: (videoInfo) => {
    return (dispatch, getState) => {
      videoInfo = videoInfo.toJS();
      dispatch(actions.playVideo(videoInfo));
      dispatch(URLActions.updateURL("video", videoInfo.guid));
      dispatch(URLActions.updateURL("live", null));
      dispatch(actions.loadAgendaAsync(videoInfo));
    };
  },

  /**
  * Asynchronous call to play a video. Not used for live streams.
  * Used by outer window/live streams as they are not guarenteed to have the necessary information to play the video.
  * @param {object} videoInfo Object containing information that can be used to identify the video.
  * @param {string} videoInfo.guid The guid of the video to be played.
  * @param {number} videoInfo.in Override default in point for video (even if video has an in metadata tag). Optional
  * @param {number} videoInfo.out Override default out point for video (even if video has an out metadata tag). Optional
  */
  playVideoAsync: (videoInfo) => {
    return async (dispatch, getState) => {
      let url = `/api/v1/api/assets?contains=guid=${videoInfo.guid}`;
      dispatch(LoaderActions.reportOut());
      let res = await fetch(url);
      if(res.ok) {
        let data = await res.json();
        let info = duckHelpers.constructDisplayFile(data[0]).toJS();
        if (videoInfo.in) {
          if (info.meta) {
            info.meta.in = videoInfo.in;
          } else {
            info.meta = {
              in: videoInfo.in
            };
          }
        }
        if (videoInfo.out) {
          if (info.meta) {
            info.meta.out = videoInfo.out;
          } else {
            info.meta = {
              out: videoInfo.out
            };
          }
        }
        dispatch(actions.playVideo(info));
        dispatch(URLActions.updateURL("video", videoInfo.guid));
        dispatch(URLActions.updateURL("live", null));
        dispatch(actions.loadAgendaAsync(info));
      }
      dispatch(LoaderActions.reportIn());
    };
  },

  loadAgendaAsync: (videoInfo) => {
    return async (dispatch, getState) => {
      let url = "/api/v1/api/agenda/" + videoInfo.rpath;
      let res = await fetch(url);
      if(res.ok) {
        let data = await res.json();
        if (data.agenda && data.agenda.items && data.agenda.items.length > 0) {
          videoInfo.agenda = data.agenda;
          dispatch(actions.playVideo(videoInfo));
        } else {
          console.warn("No agenda");
        }
      }
    };
  },

  /**
  * Synchronous passthrough call made by playVideoAsync.
  * @param {object} data Information about the video
  */
  playVideo: (data) => {
    return {
      type: "PLAY-VIDEO",
      data
    };
  },

  /**
  * Plays a live stream.
  * @param {object} data Contains relevant information about the live stream to be played.
  *   Data comes from config.js method getLiveList.
  * @param {string} data.filename The name of the livestream to be played.
  * @param {object} data.live Contains an hls and an flv url for the live stream (hls and flv members).
  * @param {string} data.id Channel id in the format of "ch[x]" where [x] is the channel number.
  */
  playLiveStream: (data) => {
    return (dispatch, getState) => {
      dispatch(URLActions.updateURL("video", null));
      dispatch(URLActions.updateURL("live", data.id));
      dispatch(actions.playLiveStreamDispatch(data));
    };
  },

  /**
  * Direct dispatch called by playLiveStream.
  * @param {object} data See playLiveStream.
  */
  playLiveStreamDispatch: (data) => {
    return {
      type: "PLAY-LIVE",
      data
    };
  },

  /**
  * Called on widget initialization to get data out of config.js asynchronously
  * @param {string} host The host url of the widget.
  */
  initializeConfigAsync: (host) => {
    return async (dispatch, getState) => {
      dispatch(LoaderActions.reportOut());
      if(process.env.NODE_ENV === "development") {
        dispatch(LoaderActions.reportIn());
        let data = sampleConfig;
        dispatch(actions.initializeConfig(data));
      } else {
        let res = await fetch("/sys/config")
        dispatch(LoaderActions.reportIn());
        if(res.ok && res.headers.get("Content-Type").includes("application/json")) {
          let data = await res.json();
          dispatch(actions.initializeConfig(data));
        } else {
          dispatch(actions.initializeConfig({}));
          let error = await res.text();
          let stat = res.status;
          console.error("ERROR LOADING CONFIG");
          console.error(stat + ": " + error);
        }
      }
    };
  },

  /**
  * Synchronous passthrough call made by initializeConfigAsync
  * @param {object} config Configuration data returned by config.js.
  */
  initializeConfig: (config) => {
    return {
      type: "INITIALIZE-CONFIG",
      config
    };
  }
};
