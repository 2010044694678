import React from "react";
import LazyLoad from "react-lazy-load";

import "./LinkItem.css"

/**
* Component that represents an item within the widget: either a video, program, or playlist.
* @prop {object} item The item that this component represents
* @prop {number} index The index of this component within the displayed items.
* @prop {array} navPath The current navPath of the BrowsingSection.
* @prop {string} playingItem The guid of the currently playing item.
* @prop {function} playVideo Used to play a video
* @prop {function} populateQueue Used to populate the video queue for autoplay
* @prop {function} setQueueIndex Used to set the current index within the autoplay queue
* @prop {function} toggleInfoModal Used to show/hide the video info modal.
*/
export default class LinkItem extends React.Component {
  shouldComponentUpdate (nextProps) {
    return (this.props.item !== nextProps.item ||
            this.props.index !== nextProps.index ||
            this.props.navPath !== nextProps.navPath ||
            this.props.playingItem !== nextProps.playingItem ||
            this.props.playcounts !== nextProps.playcounts);
  }

  /**
  * Handles click of LinkItem. For video, plays the video, and populates queue if inside a playlist or program.
  * For playlists or programs, navigates inside of them.
  */
  click = () => {
    if (this.props.item.get("type") === "program") {
      this.props.navigate(["programs", this.props.item.get("rpath")]);
    } else if (this.props.item.get("type") === "playlist") {
      this.props.navigate(["playlists", this.props.item.get("rpath")]);
    } else {
      if (this.props.navPath.get(0) === "programs" ||
      this.props.navPath.get(0) === "playlists") {
        this.props.populateQueue(this.props.navPath);
        this.props.setQueueIndex(this.props.index);
      } else {
        this.props.populateQueue();
        this.props.setQueueIndex(-1);
      }
      /*
      if(this.props.navPath.size === 2) {
        if(this.props.navPath.get(0) === 'programs') {
          this.props.populateQueue('programs', this.props.navPath.get(1))
          .then(this.props.setQueueIndex(this.props.index))
        } else if(this.props.navPath.get(0) === 'playlists') {
          this.props.populateQueue('playlists', this.props.navPath.get(1))
          .then(this.props.setQueueIndex(this.props.index))
        } else {
          this.props.populateQueue('none')
          .then(this.props.setQueueIndex(0))
        }
      }
      */
      this.props.playVideo(this.props.item);
    }
  };

  /**
  * Toggles the info modal for this link item. Only present for videos.
  */
  toggleInfo = (e) => {
    e.stopPropagation();
    this.props.toggleInfoModal(this.props.index);
  };

  /**
  * Renders a link item with a thumbnail and title. Video items also have a description and a + that can be
  * clicked on to bring up the info modal for that video. Programs have a video count.
  */
  render () {
    let {playcounts,
      item} = this.props
    let cls = "browsing-item";
    let button = "";
    if (this.props.playingItem.get("0") &&
       this.props.item.get("guid") &&
       this.props.playingItem.get("0") === this.props.item.get("guid")) {
      cls = cls + " playing";
    }
    if (this.props.item.get("meta")) {
      button = <button className='browsing-item-info-button' onClick={this.toggleInfo}>+</button>;
    }
    let viewsLabel = null
    if(playcounts && this.props.showViewCounts && item.get("type") === "video") {
      let views = playcounts.get(item.get("rpath")) || 0;
      let viewsLabelText = `${views} View${views !== 1 ? "s" : ""}`;
      viewsLabel = <p className='browsing-item-description' title={viewsLabelText}>{viewsLabelText}</p>
    }
    return (
      <div className={cls} onClick={this.click}>
        <LazyLoad height={67.5} debounce={false} offsetVertical={300}>
          <div className='browsing-item-icon-container'>
            <img className='browsing-item-icon' src={this.props.item.get("thumbnailRpath")} alt=""/>
          </div>
        </LazyLoad>
        <div className='browsing-item-contents'>
          {button}
          <p className='browsing-item-title' title={this.props.item.get("title")}>
            {this.props.item.get("title")}
          </p>
          <p className='browsing-item-description' title={this.props.item.get("subtitle")}>
            {this.props.item.get("subtitle")}
          </p>
          {viewsLabel}
        </div>
      </div>
    );
  }
}
