import React from "react";

import "./ScheduleHeader.css";

const DAYMILLIS = 1000 * 60 * 60 * 24;
const WEEKDAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const WKDYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export default class ScheduleHeader extends React.Component {
  constructor (props) {
    super(props);
    this.state = {shortNames: (window.innerWidth < 600)};
  }

  shouldComponentUpdate (newProps, newState) {
    return (this.props.view !== newProps.view ||
            this.props.viewers !== newProps.viewers ||
            this.state.shortNames !== newState.shortNames);
  }

  componentDidMount () {
    window.addEventListener("resize", this.updateDayNames.bind(this));
  }

  componentWillUnmount () {
    window.removeEventListener("resize", this.updateDayNames.bind(this));
  }

  updateDayNames () {
    this.setState({shortNames: (window.innerWidth < 600)});
  }

  playLive = () => {
    let channel = this.props.currentChannel;
    let data = {filename: channel.get("filename"),
      live: channel.get("live").toJS(),
      poster: channel.get("thumb"),
      id: "ch" + this.props.view.get("channel")};
    this.props.playLive(data);
  }

  render () {
    let header = "";
    let backButton = <div className='scheduleButtonPlaceholder' />;
    let foreButton = <div className='scheduleButtonPlaceholder' />;
    let playButton = "";
    let channelName = "";
    let viewLabel = "";
    let headClass = "noselect";
    let embedBackButton = "";
    let {showLiveViews} = this.props
    if (this.props.layout && this.props.layout === "schedule") {
      headClass += " schedule";
    }
    if (this.props.view.get("viewType") === "date") {
      if (this.props.currentChannel.get("live") && !(this.props.layout === "schedule" && !this.props.embedLivePlay)) {
        playButton = (<button className='noselect' id='listPlayButton' onClick={this.playLive}>
          <span id='playText'>Play </span>▶
        </button>);
        if(showLiveViews) {
          viewLabel = `${this.props.viewers} Currently Viewing`;
        }
      }
      channelName = this.props.currentChannel.get("filename");
      if (!this.props.startDate && !this.props.endDate) {
        if (this.props.available) {
          header = <span className={headClass} id='scheduleHeaderLabel'>Schedule Loading...</span>;
        } else {
          header = <span className={headClass} id='scheduleHeaderLabel'>Schedule Unavailable</span>;
        }
      } else {
        if (this.props.startDate && this.props.startDate < (this.props.view.get("date"))) {
          backButton = <button className='noselect' id='scheduleBackButton' onClick={() => {
            this.props.changeView({viewType: "date",
              date: (this.props.view.get("date") - DAYMILLIS),
              channel: this.props.view.get("channel")
            });
          }}>◀</button>;
        }
        if (this.props.endDate && this.props.endDate >= (this.props.view.get("date") + DAYMILLIS)) {
          foreButton = <button className='noselect' id='scheduleForwardButton' onClick={() => {
            this.props.changeView({viewType: "date",
              date: (this.props.view.get("date") + DAYMILLIS),
              channel: this.props.view.get("channel")
            });
          }}>▶</button>;
        }
        let dat = new Date(this.props.view.get("date"));
        if (this.state.shortNames || (this.props.layout && this.props.layout === "schedule")) {
          dat = WKDYS[dat.getDay()] + ", " + dat.toLocaleDateString();
        } else {
          dat = WEEKDAYS[dat.getDay()] + ", " + dat.toLocaleDateString();
        }
        header = <span className={headClass} id='scheduleHeaderLabel'>{dat}</span>;
      }
    } else if (this.props.view.get("viewType") === "search") {
      header = <span className='noselect' id='scheduleHeaderLabel'>{"Search: " + this.props.view.get("query")}</span>;
      if (this.props.layout === "schedule") {
        embedBackButton = <div id='schedembedBackButton' onClick={() => {
          this.props.changeView({viewType: "date",
            date: (this.props.previousDate),
            channel: this.props.previousChannel
          });
        }}>◀ Back</div>;
      }
    }
    return (
      <div id='scheduleHeader'>
        <div id='chanHeader'>
          {embedBackButton}
          <span className='noselect' id='chanLabel' title={channelName}>{channelName}</span>
          {playButton}
          <span className='noselect' id='chanViews' title={viewLabel}>{viewLabel}</span>
        </div>
        <div id='dateHeader'>
          {backButton}
          {header}
          {foreButton}
        </div>
      </div>
    );
  }
}
