import React from "react";

import "./InfoModal.css";

/**
* A modal for displaying information about a video.
* @prop {function} toggleInfoModal Used to show/hide the video info modal.
* @prop {function} playVideo Used to play a video
* @prop {boolean} display Whether or not to display this modal.
* @prop {object} data The data on the video to be displayed in this modal.
*/
export default class InfoModal extends React.Component {
  shouldComponentUpdate (nextProps) {
    return (this.props.display !== nextProps.display ||
            this.props.list !== nextProps.list ||
            this.props.data !== nextProps.data);
  }

  /**
  * Prevents an event from bubbling up.
  * @param {object} e The event to prevent from bubbling up.
  */
  stopBubble = (e) => {
    e.stopPropagation();
  };

  /**
  * Plays the video currently associated with the info modal, and hides the info modal.
  */
  playNow = () => {
    this.props.playVideo(this.props.data);
    this.props.toggleInfoModal();
  };

  /**
  * Renders a modal that displays more detailed information about a video.
  * Displays the video's thumbnail, which can be clicked on
  * to play the video, as well as metadata tags from the video.
  * In one column, displays title, subtitle, program, season,
  *   episode, producer, author, program language, copyright, category, and search tags.
  * In another column, displays description.
  */
  render () {
    let cls = "modal";
    let thumm = "";
    let items = [];
    let summ = "";
    let playChar = "\u25B6";
    if (this.props.display) {
      cls = cls + " show";
    }
    if (this.props.data) {
      let playcount = `${this.props.data.get("playcount")} View${this.props.data.get("playcount") !== 1 ? "s" : ""}`;
      if (this.props.data.get("thumbnailRpath")) {
        thumm = (
          <div>
            <div id='info-thumb-container' onClick={this.playNow} key={"thumb"}>
              <img id='info-thumb' src={this.props.data.get("thumbnailRpath")} alt=""/>
              <div id='info-thumb-cover'>
                <button id='info-thumb-cover-arrow'>{playChar}</button>
              </div>
            </div>
            {this.props.showViewCounts ?
              <div id='info-views'>{playcount}</div> :
              null}
          </div>
        );
      } else {
        thumm = (
          <div>
            <div>
              <button id='modal-play-button' onClick={this.playNow} key={"thumb"}>{playChar}</button>
            </div>
            {this.props.showViewCounts ?
              <div id='info-views'>{playcount}</div> :
              null}
          </div>
        );
      }
      let tags = [
        "program",
        "season",
        "episode",
        "producer",
        "author",
        "program language",
        "copyright",
        "category",
        "summary",
        "search tags"
      ];
      if (this.props.data.get("title")) {
        items.push(
          <div className='info-row' key={"title"}>
            <div className='info-text' key={"titlel"}>Title:</div>
            <div className='info-text' key={"titlev"}>{this.props.data.get("title")}</div>
          </div>
        );
      }
      if (this.props.data.get("subtitle")) {
        items.push(
          <div className='info-row' key={"description"}>
            <div className='info-text' key={"descriptionl"}>Subtitle:</div>
            <div className='info-text' key={"descriptionv"}>{this.props.data.get("subtitle")}</div>
          </div>
        );
      }
      tags.forEach((item) => {
        let label = item.charAt(0).toUpperCase() + item.slice(1);
        if (this.props.data.get("meta")[item]) {
          items.push(
            <div className='info-row' key={item}>
              <div className='info-text' key={item + "l"}>{label}:</div>
              <div className='info-text' key={item + "v"}>{this.props.data.get("meta")[item]}</div>
            </div>
          );
        }
      });
      if (this.props.data.get("meta")["description"]) {
        summ = (
          <div className='info-modal-section'>
            <p className='info-text'>Description: {this.props.data.get("meta")["description"]}</p>
          </div>
        );
      }
    }
    return (
      <div className={cls} onClick={this.props.toggleInfoModal}>
        <div className='modalContent infoModal' onClick={this.stopBubble}>
          <button className='modalCloseButton' onClick={this.props.toggleInfoModal}>&times;</button>
          <div id='info-modal-section-wrapper'>
            {thumm}
            <div id='info-modal-section-table'>
              {items}
            </div>
            {summ}
          </div>
        </div>
      </div>
    );
  }
}
