import React from "react";

/**
* Modal that displays information about the whole widget
* @prop {boolean} display Whether to display this modal or not.
* @prop {string} version The version of the widget.
* @prop {function} toggleWidgetInfoModal Function used to hide this modal.
*/
export default class WidgetInfoModal extends React.PureComponent {
  /**
  * Stops an event from propogating.
  * @param {object} e The event to stop from propogating.
  */
  stopBubble = (e) => {
    e.stopPropagation();
  };

  /**
  * Renders a modal that contains information on the widget (currently just the version number). Can be
  * shown or hid.
  */
  render () {
    let cls = "modal";
    let version = "Castus VOD Widget version " + this.props.version;
    if (this.props.display) {
      cls = cls + " show";
    }
    return (
      <div className={cls} onClick={this.props.toggleWidgetInfoModal}>
        <div className='modalContent' onClick={this.stopBubble}>
          <button className='modalCloseButton' onClick={this.props.toggleWidgetInfoModal}>&times;</button>
          <p>{version}</p>
        </div>
      </div>
    );
  }
}
