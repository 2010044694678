import React from "react";
import Hls from 'hls.js';

import "./VideoPlayer.css";

/**
* An event indicating that the HLS player is unsupported (and has encountered an error).
* @event unsupported
* @type {object}
*/

/**
* An event indicating that the video has paused.
* @event paused
* @type {object}
* @property {string} played A string representation of an array of time segments
*   indicating what parts of the video have been played.
* @property {number} currentTime The current time of the video in seconds.
*/

/**
* An event indicating that the video has seeked.
* @event seeked
* @type {object}
* @property {string} played A string representation of an array of time segments
*   indicating what parts of the video have been played.
* @property {number} currentTime The current time of the video in seconds.
*/

/**
* An event indicating that the video's time has progressed.
* @event timeupdate
* @type {object}
* @property {string} played A string representation of an array of time segments
*   indicating what parts of the video have been played.
* @property {number} currentTime The current time of the video in seconds.
*/

/**
* An event indicating that the video has begun loading.
* @event loadstart
* @type {object}
* @property {string} currentSrc The current source of the video.
*/

/**
* Video player for the widget.
* @prop {object} data Data from the VideoPlayerDuck.
* @prop {number} volume Default volume to play videos at, on a scale from 0.0 to 1.0.
* @prop {function} playNext Plays the next video in the video queue.
* @prop {function} videoLoadStart Store callback made when video is loaded.
* @prop {function} videoPlaycount Used for updating the playcount record.
* @prop {function} highlightAgenda Used for updating which agenda item is currently highlighted.
* @prop {function} iframeVideoNotSupported Store callback indicating an error with the iframe video player.
* @prop {function} htmlVideoNotSupported Store callback indicating an error with the html video player.
*/
export default class VideoPlayer extends React.Component {
  /**
  * Adds a listener for events from the iframe video player.
  * @listens unsupported
  * @listens paused
  * @listens seeked
  * @listens timeupdate
  * @listens loadstart
  */
  componentDidMount () {
    if (this.props.data.get("videoURL")) {
      this.loadRemote();
      if(this.refs.videoDisplay &&
        this.props.data.get("playMode") === "live") {
        let video = this.refs.videoDisplay
        // If hls can't be played, use hls.js
        if(!(video.canPlayType("application/vnd.apple.mpegurl"))) {
          this.createHls();
        }
      }
    }
  }

  componentWillUnmount () {
    this.destroyHls();
  }

  /**
  * When reveiving a new time change event, change the current time of the video.
  *   Time events have a uuid that distinguishes them even if the time to seek to is the same.
  * When video url changes, destroy the current hls instance if it exists
  */
  componentWillReceiveProps (nextProps) {
    // Handle time events
    if (this.props.data.get("timeEvent") !== nextProps.data.get("timeEvent")) {
      if (this.props.data.get("htmlVideoSupported")) {
        this.refs.videoDisplay.currentTime = nextProps.data.getIn(["timeEvent", "time"]);
      }
    }
    // Destroy hls
    if(this.props.data.get("videoURL") !== nextProps.data.get("videoURL")) {
      this.destroyHls();
    }
  }

  shouldComponentUpdate (nextProps) {
    return this.props.data !== nextProps.data;
  }

  componentDidUpdate (prevProps) {
    // If a video was playing and the video player connects
    //  to chromecast, restart playing the video to sync up
    //  with chromecast
    if (this.props.data.get("connected") &&
      !(prevProps.data.get("connected")) &&
      this.props.data.get("videoURL")) {
      if (!this.refs.videoDisplay.paused) {
        this.refs.videoDisplay.pause();
        this.refs.videoDisplay.currentTime = 0;
      }
      this.loadRemote();
    }
    // When video changes, create a new hls.js instance
    if(this.props.data.get("videoURL") !== prevProps.data.get("videoURL")) {
      if(!this.props.data.get("autoplay")) {
        this.props.setVideoData({playing: false});
      }
      if(this.refs.videoDisplay) {
        let video = this.refs.videoDisplay
        // If hls can't be played, use hls.js
        if(this.props.data.get("playMode") === "live" &&
          !(video.canPlayType("application/vnd.apple.mpegurl"))) {
          this.createHls();
        }
      }
    }
  }

  createHls = () => {
    let video = this.refs.videoDisplay
    let autoplay = this.props.data.get("autoplay");
    if(Hls.isSupported()) {
      let hls = new Hls({autoStartLoad: (autoplay ? true : false)});
      hls.attachMedia(video);
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(this.props.data.get("videoURL"));
      })
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if(autoplay) {
          video.play();
        }
      });
      this.hls = hls;
    } else {
      // If hls.js is not supported (i.e. IE), fall back to flash
      this.props.htmlVideoNotSupported();
    }
  }

  destroyHls = () => {
    if(this.hls) {
      this.hls.destroy();
      this.hls = null;
    }
  }

  /**
   * Returns true if currently casting to ChromeCast.
   */
  isCasting = () => {
    return (this.props.data.get("ccPlayer") &&
      this.props.data.get("ccPlayerController") &&
      this.props.data.get("ccPlayer").isConnected);
  }

  /**
  * Called when any video loads. Sets volume based on volume url tag, then fires store-side video initialization event.
  */
  videoLoad = () => {
    if (this.props.volume || this.props.volume === 0) {
      this.refs.videoDisplay.volume = this.props.volume;
    }
    this.loadRemote();
    this.props.setVideoData({volume: this.refs.videoDisplay.volume, textTracks: this.refs.videoDisplay.textTracks});
  };

  videoLoaded = () => {
    if (this.props.data.get("autoplay")) {
      if (this.isCasting()) {
        this.playWithRemote();
      } else {
        this.refs.videoDisplay.play();
      }
    }
  };

  loadRemote = () => {
    if (window.cast && this.isCasting()) {
      let castSession = window.cast.framework.CastContext.getInstance().getCurrentSession();
      if (castSession) {
        let ccPlayer = this.props.data.get("ccPlayer");
        let ccPlayerController = this.props.data.get("ccPlayerController");
        // let url = this.props.data.get("videoURL");
        // TEMPORARY DEVELOPMENT FIX
        let rpath = "";
        if (this.props.data.get("videoURL")) {
          let rpathRegEx = /(\/live\/ch[0-9]+\.m3u8)|(\/(?:[0-9a-e]\/)+.*)/;
          rpath = rpathRegEx.exec(this.props.data.get("videoURL"))[0];
        }
        let url = window.location.protocol + "//test.vod.castus.tv/vod/dl.php/" + rpath;
        let mediaInfo = new window.chrome.cast.media.MediaInfo(url, "video/mp4");
        let request = new window.chrome.cast.media.LoadRequest(mediaInfo);
        castSession.loadMedia(request).then(
          () => {
            ccPlayerController.playOrPause();
            ccPlayer.currentTime = 0;
            ccPlayerController.seek();
            if (this.props.data.get("autoplay")) {
              this.playWithRemote();
            }
          },
          function (errorCode) { console.log("Error code: " + errorCode); });
      }
    }
  }

  playWithRemote = () => {
    if (this.refs.videoDisplay.readyState >= 2 &&
      this.props.data.get("ccPlayer").isMediaLoaded) {
      this.refs.videoDisplay.play();
      this.props.data.get("ccPlayerController").playOrPause();
    }
  }

  /**
  * Called when any video ends.
  * Plays the next video in the queue if there is a queue, and updates playcount.
  * Playcount update should possibly go before playNext?
  */
  videoEnd = () => {
    this.timeUpdateForce();
    this.props.playNext();
    this.props.setVideoData({playing: false});
  };

  /**
  * Called when any video pauses.
  * Updates playcount, and, if past the time indicated in the out metadata tag of the video,
  *   plays the next video in the queue if there is one.
  */
  videoPause = () => {
    if (this.isCasting() && !(this.props.data.get("ccPlayer").isPaused)) {
      this.props.data.get("ccPlayerController").playOrPause();
    }
    this.timeUpdateForce();
    this.props.setVideoData({playing: false});
    if (this.props.data.get("endTime") !== -1 && this.refs.videoDisplay.currentTime >= this.props.data.get("endTime")) {
      this.props.playNext();
    }
  };

  /**
   * Called when the video plays. Used for the video controls
   */
  videoPlay = () => {
    if(this.hls && this.props.data.get("autoplay") !== 'true') {
      this.hls.startLoad();
    }
    if (this.isCasting() && this.props.data.get("ccPlayer").isPaused) {
      this.props.data.get("ccPlayerController").playOrPause();
    }
    if (this.props.data.get("playcountToken") === null) {
      if(this.props.data.get("playMode") === "live") {
        this.props.videoLoadStart(this.refs.videoDisplay.getAttribute("data-rpath"));
      } else {
        this.props.videoLoadStart(this.refs.videoDisplay.currentSrc);
      }
    }
    this.props.setVideoData({playing: true});
  };

  /**
  * Only called by video player.
  *   Stops right-click context menu from functioning if the context menu is turned off in config.js.
  */
  videoContextMenu = (e) => {
    if (!this.props.data.get("contextMenu")) {
      e.preventDefault();
    }
  };

  videoSeeked = (e) => {
    this.timeUpdateForce();
    if (this.isCasting()) {
      this.props.data.get("ccPlayer").currentTime = this.refs.videoDisplay.currentTime;
      this.props.data.get("ccPlayerController").seek();
    }
  }

  /**
   * Called by video player to update volume level in controls
   */
  videoVolumeChange = () => {
    this.props.setVideoData({volume: this.refs.videoDisplay.volume});
  };

  /**
  * Called periodically by video player when video is playing. Updates playcount and agenda.
  */
  timeUpdate = () => {
    let {currentTime, played, duration} = this.refs.videoDisplay;
    this.props.setVideoData({currentTime, duration});
    this.props.videoPlaycount(played, Date.now(), false);
    this.props.highlightAgenda(currentTime);
  };

  /**
  * Called periodically by iframe video player when video is playing. Updates playcount and agenda.
  * @param {array} played Array of time segments of video that have been played.
  * @param {number} currentTime Current time of the video.
  */
  timeUpdateRemote = (played, currentTime) => {
    this.props.videoPlaycount(played, Date.now(), false);
    this.props.highlightAgenda(currentTime);
  };

  /**
  * Called in order to force the playcount to update regardless of video time, as well as update agenda.
  */
  timeUpdateForce = () => {
    this.props.videoPlaycount(this.refs.videoDisplay.played, Date.now(), true);
    this.props.highlightAgenda(this.refs.videoDisplay.currentTime);
  };

  /**
  * Called by iframe video to force the playcount to update regardless of video time, as well as update agenda.
  * @param {array} played Array of time segments of video that have been played.
  * @param {number} currentTime Current time of the video.
  */
  timeUpdateForceRemote = (played, currentTime) => {
    this.props.videoPlaycount(played, Date.now(), true);
    this.props.highlightAgenda(currentTime);
  };

  /**
  * Renders a video player with two fallbacks. First fallback is a non-react HLS.js video player in an iframe.
  * Second fallback is a flash player. Fallbacks are used when onError is fired by video players.
  */
  render () {
    let rpath = "";
    let cls = "video-display";
    let controlsList = "";
    if (this.props.nogui) {
      cls = cls + " full";
    }
    let urlRegEx = /dl.php(.*)/.exec(this.props.data.get("videoURL"));
    let posterURL = "";
    let capTrack = "";
    if (urlRegEx && typeof urlRegEx === typeof []) {
      posterURL = ("/vod/thmb.php" + urlRegEx[1]);
      capTrack = <track kind='captions' src={"/caption" + urlRegEx[1]} srcLang='en' default />;
    } else if (this.props.data.get("livePoster")) {
      posterURL = this.props.data.get("livePoster");
    }
    if (this.props.data.get("videoURL")) {
      let rpathRegEx = /(\/live\/ch[0-9]+\.m3u8)|(\/(?:[0-9a-e]\/)+.*)/;
      let rpathMatch = rpathRegEx.exec(this.props.data.get("videoURL"));
      if(rpathMatch) {
        rpath = rpathMatch[0];
      }
    }
    if (this.props.data.get("noDownload")) {
      controlsList = "nodownload";
    }
    if (this.props.data.get("htmlVideoSupported")) {
      return (
        <video className={cls}
          crossOrigin="anonymous"
          controls={false}
          controlsList={controlsList}
          onEnded={this.videoEnd}
          onSeeked={this.videoSeeked}
          onPlay={this.videoPlay}
          onPause={this.videoPause}
          onTimeUpdate={this.timeUpdate}
          onLoadStart={this.videoLoad}
          onLoadedData={this.videoLoaded}
          onContextMenu={this.videoContextMenu}
          onVolumeChange={this.onVolumeChange}
          key={this.props.data.get("videoURL")}
          poster={posterURL}
          ref='videoDisplay'
          data-rpath={rpath}
          onError={(e) => {
            console.error(e);
          }}>
          <source src={this.props.data.get("videoURL")} />
          {capTrack}
        </video>
      );
    } else {
      let videoURL = new URL(this.props.data.get("videoURL"), window.location);
      return (<embed
        src={"/vod/swf/StrobeMediaPlayback/StrobeMediaPlayback_hls_mss.swf?" +
          "controlBarMode=docked&autoPlay=true&src=" +
          encodeURIComponent(videoURL)}
        className={cls}
        width='100%'
        height='100%'
        allowFullScreen={true}
        wmode='opaque'
        type='application/x-shockwave-flash' />
      );
    }
  }
}
